.ask-questions-container{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.ask-questions {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
}




.still-have-questions{
  
    text-align: center;
}

.still-have-questions header {
    font-size: 40px;
    color: var(--primary-color);
}

.still-have-questions header h3 span{
    font-weight: 100;
    font-family:Alegreya ;
    font-style: italic;
}

.still-have-questions p{
    font-size: 13px;
    font-weight: 600;
    color: var(--sub-text);
    margin: 20px 0px;
}

.still-have-questions button{
    width: 110px;
    height: 30px;
    border: none;
    border-radius: 10px;
    background-color: var(--primary-color);
    color: var(--bg-light);
}

/* FREQUENTLY ASKED QUESTION FOR CLIENT  */
.faq-for-client{
    width: 90%;
    margin: auto;
}

/* .heading-question-and-answers{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin-bottom: 30px;
} */

.payment-for-client{
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    gap: 20px;
    margin-bottom: 30px;
    color: var(--primary-color);
   
}



.payment-for-client-left{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* width:50%; */
    text-align: start;
    cursor: pointer;
    /* margin-top: 10px; */
    margin-bottom: 40px;
}

.payment-for-client-left-heading{
    width: 20%;
}



.payment-for-client-left-heading h4{
    font-size: 17px;
    color: var(--sub-text);
}

.payment-for-client-right{
    width: 70%;
    text-align: start;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
}

/* .payment-for-client-right h2{
    margin-bottom: 10px;
    
} */

.question-with-answers-forclient{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.question-with-answers h4{
    font-size: 17px;
}

.question-with-answers-forclient  button{
    border: none ;
    background-color: transparent;
    color: var(--primary-color);
}

.answers-for-client{
    margin: 10px 0px 0px 27px;
    /* margin-left: 27px; */

}

.answers-for-client p{
    font-size: 13px;
    color: var(--sub-text);
    line-height: 20px;
}


@media(max-width:450px){
    .payment-for-client-left{
        width: 100%;
        display: block;
        align-items: flex-start;
        justify-content: space-between;
        /* width:50%; */
        text-align: start;
        cursor: pointer;
        /* margin-top: 10px; */
        margin-bottom: 40px;
    }

    .payment-for-client-left-heading{
        width: 100%;
    }

    .payment-for-client-left-heading h4{
        width: 100%;
        font-size: 17px;
        color: var(--sub-text);
        margin-bottom: 10px;
    }

    .payment-for-client-right{
        width: 100%;
        text-align: start;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        cursor: pointer;
    }

    .payment-for-client h4:nth-child(2){
        display: none;
    }
}

@media(max-width:800px){
    .payment-for-client p:nth-child(2){
        display: none;
    }
}