.single-blog-hero-container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.single-blog-hero-left {
  width: 48%;
}

.single-blog-hero-left img {
  width: 100%;
}

.single-blog-hero-right {
  width: 48%;
}

.single-blog-hero-right h1 {
  margin-bottom: 20px;
  width: 60%;
}

.single-blog-hero-right p {
  font-size: 14px;
  color: var(--sub-text);
  line-height: 25px;
  width: 60%;

}

/* SINGLE-BLOG-WRITEUP */

.single-blog-writeup {
  width: 70%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  line-height: 30px;
}
.single-blog-writeup h4 {
  margin-bottom: 10px;
  font-size: 20px;
}

.single-blog-writeup p {
  margin-bottom: 30px;
  font-size: 13px;
  color: var(--sub-text);
}

.single-blog-writeup figure {
  width: 100%;
  margin-bottom: 30px;
}

.single-blog-writeup figure img {
  width: 100%;
}

.written-by-PSP-team {
  display: flex;
  align-items: first baseline;
  /* justify-content: center; */
  gap: 10px;
}

.orange-dot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: var(--secondary-color);
}

/* SINGLE PAGE SIDEBAR */
.single-blog-sidebar {
  width: 70px;
  padding: 10px;
  border-radius: 0px 20px 20px 0px;
  background-color: #F3F3F3; 
  position: fixed;
  z-index: 1;
  top: 30vh; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  height: 200px;
  justify-content: space-between;
}

.single-blog-sidebar figure {
  width: 30px;
  height: 30px;
}

.single-blog-sidebar figure img {
  width: 100%;
}


@media(max-width: 450px){
  .single-blog-hero-container {
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .single-blog-hero-left {
    width: 100%;
  }

  .single-blog-hero-right {
    width: 100%;
  }
  
  .single-blog-hero-right h1 {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  
.single-blog-hero-right p {
  font-size: 14px;
  color: var(--sub-text);
  line-height: 25px;
  width: 100%;
  text-align: center;

}

.single-blog-writeup h4 {
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
}


.single-blog-writeup p {
  margin-bottom: 30px;
  font-size: 13px;
  color: var(--sub-text);
  text-align: center;
}

}