.wallet-card-container {
  display: flex;
  flex-wrap: wrap;

  > .single-card-container {
    flex: 1;
    padding: 20px;
    margin: 10px;
    box-sizing: border-box;
    aspect-ratio: 16/9;
    border-radius: 10px;
    max-height: 273px;
    height: 100%;
    position: relative;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      > .wallet-card-logo {
        width: 41.66px;
        height: 48.98px;
      }

      > .wallet-card-design {
        position: absolute;
        top: 25px;
        right: 20px;
        z-index: 0;
      }

      > div {
        > p {
          font-weight: 500;
          font-size: 13.16px;
          line-height: 18.92px;
          margin-bottom: 10px;
        }

        > div {
          display: flex;
          gap: 10px;

          > p {
            font-weight: 700;
            font-size: 25px;
            line-height: 36.18px;
          }

          > img {
            cursor: pointer;
          }
        }
      }

      > button {
        border-radius: 14px;
        background-color: #f5f6f9;
        padding: 11px;
        border: none;
        color: #1c2634;
        font-weight: 600;
        font-size: 18px;
        position: relative;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
}

.fundWallet-input {
  width: 100%;
  outline: none;
  border: 1px solid #eff3fa;
  height: 52px;
  padding: 10px;
  border-radius: 10px;
}
