.labelContainer {
 display: flex;
 align-items: center;
 position: relative;
 padding: 1em 0em;

 .label {
  width: 33%;
  flex-shrink: inherit;
  margin: 0px 10px;
 }
}

 .line {
  width: 30%;
  border-top: 1px solid #DFE8F6;
 }