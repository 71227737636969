.displayDetailsBg {
  background-image: url("../../../../../assets/website/images/images/Group\ 427320686.svg");
  background-repeat: no-repeat;
  background-position: center;

  .displayDetails_container {
    background-color: white;
    padding: 2em 2em 6em 2em;
    color: #7f879e;
    box-shadow: 1px 1px 10px 4px #fcfcfc;
    border-radius: 16px;
    width: 65%;
    margin: auto;

    header {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      padding: 1em 0em;
    }
    .displayDetails_img {
      text-align: center;
      padding-bottom: 1em;
      border-bottom: 1px solid #dfe8f6;

      div {
        font-size: 24px;
        font-weight: 600;
        line-height: 31.2px;
        font-family: "Plus Jakarta Sans";
        padding: 0.5em 0em;
      }
      span {
        font-size: 18px;
        font-weight: 600;
        line-height: 23.4px;
      }
    }
    .displayDetails_skill {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 10px;
      span {
        text-align: center;
        background-color: #f9f9f9;
        padding: 8px 14px;
        border-radius: 12px;
      }
    }
  }
}
