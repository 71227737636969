.borderIdentityTag_container {
  border: 1px solid #eff3fa;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 15px;
  margin-bottom: 1.5em;

  .borderIdentityTag_img {
    width: 25%;
    margin-left: 0px;
  }
  .borderIdentityTag_body {
    width: 75%;
    text-align: left;
    line-height: -20px;
    div {
      font-size: 20px;
      letter-spacing: -3%;
      line-height: 30px;
      font-weight: 700;
      font-family: "Plus Jakarta Sans";
    }
    span {
     font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      font-family: "Plus Jakarta Sans";
    }
  }
  .borderIdentityTag_check {
    float: right;
  }
}
