.button-container {
  button {
    background-color: #134271;
    border-radius: 12px;
    padding: 12px;
    width: 100%;
    color: white;
    font-size: 16px;
    font-family: "Plus Jakarta Sans";
    line-height: 20px;
    cursor: pointer;
    border: none;
  }
}

.outlined-button-container {
  button {
    background-color: transparent;
    border-radius: 16px;
    // padding: 10px 17px;
    color: var(--complimentary);
    font-size: 16px;
    font-family: "Plus Jakarta Sans";
    line-height: 20px;
    cursor: pointer;
    border: 1px solid var(--complimentary);
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
}

.colored-button-container {
  button {
    border-radius: 100px;
    font-size: 12px;
    font-family: "Plus Jakarta Sans";
    cursor: pointer;
    border: none;
  }
}
