.auth_container {
  display: flex;
  padding: 2em 0em;
}
.authBanner {
  width: 40%;
  margin: auto;
  position: relative;
  img {
    width: 100%;
  }
  .authBannerLabel {
    position: absolute;
    right: -80px;
    bottom: 0;
    width: 90%;
  }
  .bannerHeader {
    h1 {
      color: #134271;
      font-size: 56px;
      line-height: 82px;
      font-weight: 800;
      font-family: "Plus Jakarta Sans";
      letter-spacing: -3%;
      width: 90%;
    }
    p {
      color: #1c2634;
      font-size: 20px;
      line-height: 36px;
      font-weight: 400;
      font-family: "Plus Jakarta Sans";
      letter-spacing: -3%;
      width: 70%;
    }
  }
}

.authForm {
  background-color: white;
  padding: 2em;
  text-align: center;
  width: 35%;
  margin: auto;
  border-radius: 12px;
  header {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    font-family: "Plus Jakarta Sans";
    letter-spacing: -3%;
    padding-bottom: 1.5em;
    padding-top: 1em;
    .authForm_sub_header {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      font-family: "Manrope";
      letter-spacing: -2%;
      color: #848fac;
      padding-top: 0.4em;
    }
  }
  .forgotPassword {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-family: "Plus Jakarta Sans";
    text-align: right;
    margin-top: 0.5em;
    cursor: pointer;
  }
  .withGoogle {
    border: 1px solid #dfe8f6;
    border-radius: 8px;
    padding: 12px 0px;
    margin-top: 1.5em;
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    color: #1c2634;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .options {
    font-size: 16px;
    line-height: 16px;
    color: #7f879e;
    margin-top: 1.5em;
    span {
      color: #134271;
      cursor: pointer;
      font-weight: 700;
    }
  }
}

.terms {
  color: #a9b4cd;
  font-size: 16px;
  line-height: 28.8px;
  letter-spacing: -2%;
  font-weight: 400;
  padding-top: 1em;
}

.verifyInput_Container {
  display: flex;
  width: 100%;
  gap: 10px;
  padding-bottom: 2em;
  input {
    border: 1px solid #eff3fa;
    border-radius: 15px;
    padding: 27px 24px;
    width: 16.6%;
    outline: none;
  }
  input:focus {
    border: 1px solid #134271;
  }
}

.passwordIndicator {
  text-align: left;
  .passwordIndicator_Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .passwordIndicator_inputContainer {
      display: flex;
      align-items: center;
      width: 80%;
      & > .indicator-item {
        height: 6px;
        background-color: #dfe8f6;
        border: none;
        outline: none;
        width: 16.6%;
        margin: 0px 5px;
        border-radius: 4px;
      }

      & > .indicator-item.filled {
        background-color: green;
      }
    }
    span {
      color: #229337;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -2%;
      font-weight: 700;
      margin: auto;
    }
  }
  span {
    color: #a9b4cd;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -1%;
    font-weight: 400;
  }
}

.privacyPolicy {
  color: #a9b4cd;
  text-align: left;
  font-size: 14px;
  line-height: 25.5px;
  letter-spacing: -1%;
  font-weight: 400;
  display: flex;
  gap: 10px;
  padding: 1em 0em;
  span {
    color: #134271;
  }
}
