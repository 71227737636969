@import "../mixins";

.messages-container {
  box-shadow: 0px 0px 1px 0px rgb(198, 202, 206);
  .details-row {
    gap: 0px;
  }
  .message-summaries,
  .message-body {
    border-radius: 0;
  }
}

.user-message {
  @include flex-none-center;
  border-bottom: 1px solid rgba(var(--primary-rgb), 0.2);

  gap: 8px;
  margin: 10px 0px;
  padding: 5px;

  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;

    img,
    svg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center top;
    }
  }

  .text {
    width: 78%;
    .name-time {
      @include flex-between-center;

      .name {
        font-weight: 600;
        font-size: 14px;
        @include flex-none-center;
        gap: 10px;

        .dot {
          color: var(--primary-color);
          font-size: 12px;
        }
      }

      .time {
        font-size: 11px;
        color: var(--text-grey);
      }
    }

    .message {
      height: 20px;
      width: 100%;
      font-size: 13px;
      margin: 5px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .msg-unread {
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .col-span-6 {
    grid-column: span 6;
  }
  
  .col-span-1 {
    width: 'fit-content';
    grid-column: span 1; 
    background: rgb(195, 4, 4);
    border-radius: 5px;
    color: white;
    font-size: 10px !important;
    font-weight: 700;
    line-height: 18px;
  }
}

.user-message-active {
  @extend .user-message;
  &:hover {
    background-color: rgba(var(--primary-rgb), 0.05);
    cursor: pointer;
  }
}

.active-user-message {
  @extend .user-message;
  background-color: rgba(var(--primary-rgb), 0.05);
}

.message-summaries {
  width: 30%;
  padding: 10px;
  height: 89vh;

  .search-messages {
    padding: 10px;
    width: 100%;
    padding-left: 30px;
    border: none;
    outline: none;
    background-repeat: no-repeat;
    background-position: 5px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 0px var(--primary-color);
    background-image: url(../../assets/dashboard/icons/search.png);
  }
}
.message-body-content { 
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.message-body {
  .user-message {
    position: relative;
    // margin-bottom: 50px;

    .avatar {
      width: 56px;
      height: 56px;
      position: relative;
      .online-sign {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #19ad31;
        position: absolute;
        right: 5px;
        bottom: 0;
        font-size: 12px;
      }
    }

    .date {
      position: absolute;
      bottom: -15px;
      background-color: white;
      width: 100px;
      left: 40%;
      margin: auto;
      border-radius: 6px;
      padding: 5px;
      box-shadow: 0px 0px 1px 0px var(--primary-color);
    }


  }
}

.chat-body {
  height: 64vh;
  overflow-y: auto;
  scrollbar-width: 0px;
  // margin-top: -50px;
}

.message-block {
  @include flex-none-start;
  gap: 8px;
  margin: 0px;
  padding: 5px;

  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;

    img,
    svg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center top;
    }
  }

  .text {
    width: 94%;
    .msg-box {
      margin-top: 10px;
    }
    .name-time {
      @include flex-between-center;

      .name {
        font-weight: 600;
        font-size: 14px;
        @include flex-none-center;
        gap: 10px;

        .dot {
          color: var(--primary-color);
          font-size: 12px;
        }
      }
    }
    .time {
      font-size: 8px;
      color: var(--text-grey);
      margin: 0px;
      clear: both;
    }
    .message {
      max-width: 90%;
      width: fit-content;
      font-size: 13px;
      line-height: 20px;
      margin: 0px;
      padding: 2px 10px;
      border-radius: 0px 10px 10px 10px;
      box-shadow: 0px 0px 2px 0px rgb(198, 202, 206);
    }
  }

 
}

.receivers-end {
  flex-direction: column;

  .text{
    width: 94%;
    margin-top: -10px;
  }
  
  .text,  .avatar-section {
    .message {
      border-radius: 0px 10px 10px 10px;
      float: left;
      clear: both;
      background: rgba(var(--primary-rgb), 0.05) !important;
      // color: white !important;
    }
  }
  .time {
    color: var(--text-grey);
    text-align: right;
    float: right;
    clear: both;
    padding:  0 13px !important;
    margin-top: 10px !important;
    padding-top: 2px;
  }
}

.senders-end {
  flex-direction: column;

  .text {
    width: 94%;
    margin-top: -3px;
  }

  .text,
  .avatar-section {
    .message {
      border-radius: 10px 0px 10px 10px;
      float: right;
      clear: both;
    }
  }
  .time {
    color: var(--text-grey);
    // margin: 5px 0px;
    text-align: right;
    float: right;
    margin-top: 10px !important;
    // padding-left:  0 13px !important;
    padding-top: 2px;
  }

  .avatar-section {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;

    .name {
      font-weight: 600;
    }
  }
}


.text-box-container {
  position: fixed;
  right: 35px;
  left: 46%;
  bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px rgb(198, 202, 206);
  background-color: white;
}
.text-box {
  display: flex;
  input {
    width: 85%;
    padding: 10px;
    background-image: url(../../assets/dashboard/icons/reply.svg);
    background-position: 3px;
    background-repeat: no-repeat;
    padding-left: 40px;
    border: none;
    outline: none;
  }

  .emoji-button-section {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    user-select: none;

    .emoji {
      font-size: 24px;
      color: grey;
    }

    .send-btn {
      width: 65px;
      background-color: var(--primary-color);
      border-radius: 5px;
      padding: 5px;
      @include flex-center-center;
    }
  }
}
