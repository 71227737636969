@mixin inline-flex {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.application {
  padding-top: 33px;

  &_title {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #1c2634;
    padding-bottom: 11px;
  }

  &_description {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #475367;
  }

  &__btns {
    @include inline-flex;
    gap: 12px;

    & > .btn {
      @include inline-flex;
      font-family: "Plus Jakarta Sans";
      font-size: 12px;
      font-weight: 600;
      line-height: 22.04px;
      width: 97px;
      height: 32px;
      border: 1px solid;
      border-radius: 10px;
      cursor: pointer;

      &.hired {
        background-color: #134271;
        border-color: #134271;
        color: #ffffff;
      }

      &.reject {
        border-color: #eff3fa;
        background-color: #ffffff;
        color: #1c2634;
      }
    }
  }
}

.application-hired-status-container {
  background: rgba(34, 147, 55, 0.2);
  padding: 5px 13px;
  max-width: 97px;
  border-radius: 13px;

  > p {
    color: #229337;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
}

.application-rejected-status-container {
  background: rgba(244, 32, 32, 0.2);
  padding: 5px 13px;
  max-width: 97px;
  border-radius: 13px;

  > p {
    color: #f42020;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
}
