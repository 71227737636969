.filter-container {
  display: flex;
  gap: 12px;
  margin: 20px 0;

  > .single-filter-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;

    > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    > p:last-child {
      font-size: 12px;
      width: 23px;
      text-align: center;
      border-radius: 12px;
    }
  }
}
