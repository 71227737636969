@import "../mixins";

.find-job-container {
  width: 97%;
  margin: auto;
  .search-background {
    background-color: white;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0px 0px 1px 0px grey;
  }

  .search-button-section {
    @include flex-between-center;
    height: 40px;
    background-color: #eff3fa;
    padding-left: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px grey;

    .search-section {
      width: 90%;
      height: 60%;

      @include flex-none-center;
      input {
        width: 33.33%;
        height: 100%;
        padding-left: 30px;
        border: none;
        outline: none;
        border-right: 1px solid #a9b4cd;
        background-color: #eff3fa;
        background-repeat: no-repeat;
        background-position: 5px;
      }

      .jobs {
        background-image: url(../../assets/dashboard/icons/search.png);
      }
      .location {
        background-image: url(../../assets/dashboard/icons/location.svg);
      }
      .category {
        background-image: url(../../assets/dashboard/icons/copy.png);
      }
    }
    button {
      height: 100%;
      margin: 0;
      width: 15%;
      background-color: var(--primary-color);
      border: none;
      color: white;
      border-radius: 0px 7px 7px 0px;
    }
  }

  .sort-view-section {
    margin: 20px 0px;
    @include flex-between-center;
    .item {
      display: flex;
      align-items: center;
      gap: 5px;

      label {
        font-weight: 200;
        font-size: 14px;
      }

      select {
        border: none;
        background-color: transparent;
        outline: none;
      }

      .icon {
        @include flex-none-center;
        font-size: 18px;
      }
      .text {
        font-size: 14px;
      }
    }

    .sort-section,
    .switch-view {
      @include flex-none-center;
      gap: 15px;
    }

    .switch-view {
      .item {
        color: #a9b4cd;
        padding: 5px 7px;
        border-radius: 8px;
        box-shadow: 0px 0px 1px 0px transparent;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }

      .active-view {
        @extend .item;
        color: var(--primary-color);
        font-weight: 600;
        box-shadow: 0px 0px 1px 0px var(--primary-color);
      }
    }
  }
  .cards-section {
    margin: 20px 0px;
  }
  .grid-job-cards {
    @include flex-between-center;
    flex-wrap: wrap;
    .card {
      width: 49%;
      transition: all 0.3s ease-in-out;
    }
  }
  .list-job-cards {
    .card {
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }

  .card {
    margin: 10px 0px;
  }
}

.job-card-container {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgb(196, 195, 197);
  .card-header {
    @include flex-between-center;
    .company {
      @include flex-none-center;
      gap: 10px;
      p {
        color: var(--text-grey);
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 10px;
        text-transform: capitalize;
      }
      .icon {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        overflow: hidden;

        img,
        svg {
          width: 100%;
          height: 100%;
          object-fit: fit;
        }
      }
    }

    .time-posted {
      background-color: var(--bg-grey);
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 20px;
    }
  }

  .skills-budget-section {
    @include flex-between-center;
    margin: 15px 0px;

    .skills,
    .time-budget {
      @include flex-none-center;
      gap: 5px;
      color: grey;
    }

    .skill {
      box-shadow: 0px 0px 1px 0px grey;
      padding: 5px;
      border-radius: 10px;
      font-size: 12px;
      color: var(--text-grey);
    }

    .time {
      font-size: 14px;
    }

    .budget {
      color: var(--primary-color);
      font-weight: 600;
      padding: 5px 10px;
      border-radius: 10px;
      background-color: rgba(var(--primary-rgb), 0.1);
      font-size: 14px;
    }
  }

  .line {
    width: 80%;
    margin: 15px auto;
    border: 0.5px solid var(--bg-grey);
    height: 1px;
  }

  .job-card-footer {
    justify-content: space-between;
  }
}

.job-card {
  margin: 10px 0px;
}
