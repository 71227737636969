.input {
  border: 1px solid #dfe8f6;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  margin: 15px 0px;
  width: 100%;
  //  margin-bottom: 1.5em;
  input {
    outline: none;
    font-size: 14px;
    letter-spacing: -2%;
    line-height: 21px;
    font-weight: 500;
    font-family: "Plus Jakarta Sans";
    border: none;
    // max-width: 508px;
    width: 100%;
    padding: 12px 0px;
  }
  img {
    cursor: pointer;
  }
}

.verticalInput {
  line-height: 18px;
  font-weight: 400;
  &::placeholder {
    color: #cad3e2;
    opacity: 1;
  }
}
