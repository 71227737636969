/* HIRE NOW */
.hire-now {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  color: var(--text-light);
  padding: 10px;
  font-size: 14px;
  font-family: Plus Jakarta Sans;
}

.hire-link {
  color: var(--bg-light);
  font-weight: 200;
}
/* NAVIGATIOM */

/* HERO SECTION */
.hero-section {
  margin: auto;
}

.hero {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.hero figure img {
  object-fit: cover;
}

.hero figure {
  width: 20%;
}
.hero figure img {
  width: 100%;
}

.hero-middle {
  width: 60%;
  text-align: center;
}

.hero-middle header h1 {
  font-size: 60px;
  color: var(--primary-color);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}
.hero .subtitle {
  margin: 20px 0px;
  color: #7f879e;
}
.seamless {
  color: var(--secondary-color);
  font-family: "Alegreya", serif;
  font-style: italic;
}

.news {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 300px;
  margin: auto;
  margin-top: 50px;
  background-color: #ebf2f7;
  border-radius: 10px;
}
.news p:nth-of-type(1) {
  background-color: #134271;
  padding: 7px 22px;
  border-radius: 30px;
  color: white;
}
.searchBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* height: 60px; */
  width: 85%;
  margin: 10px auto;
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgb(218, 213, 213);
}
.searchBar input {
  /* height: 30px; */
  width: 80%;
  border: none;
  outline: none;
  padding: 10px;
}
.searchBar button {
  /* padding: 22px 50px; */
  width: 100px;
  height: 50px;
  border: none;
  background-color: #134271;
  color: white;
  border-radius: 0px 10px 10px 0px;
}

.popular-search {
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* letter-spacing: 10px; */
}
.popular-search-buttons {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  gap: 10px;
}
.popular-search button {
  padding: 10px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
  border: none;
  color: #134271;
  border-radius: 10px;
  margin: 10px 0px;
}

/* TRUSTED BY */
.trusted-by {
  width: 100%;
  margin: 50px 0px;
  padding: 10px 0px;
}

.trusted-brands {
  display: flex;
  justify-content: center;
  color: var(--bg-dark);
  letter-spacing: 0.5px;
}

.brand-container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 20px auto;
}

.brand {
  width: 20%;
  height: 25px;
}

.brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.find-talents h1 {
  font-size: 45px;
  font-weight: 500;
}

/* ELEVATING YOUR PROFESSIONAL PERFORMANCE */

.ProfPerformance {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.ProfPerformance-left {
  width: 45%;
  padding: 20px;
}
.ProfPerformance-left img {
  width: 100%;
  object-fit: cover;
}

.profPerformance-right {
  width: 50%;
}
.profPerformance-right header h1 {
  font-size: 45px;
  width: 90%;
}

.ProfPerformance-details {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 30px;
}
.ProfPerformance-details p {
  color: var(--sub-text);
  font-size: 14px;
  margin: 5px 0px;
}

.profPerformance-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  border-radius: 10px;
  border: none;
  color: var(--bg-light);
  margin: 30px;
}
/* FIND TALENT */

.find-talents {
  text-align: center;
}
.find-talents h1 {
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
}

.top-skill-container {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.top-skill {
  text-align: center;
}

.top-skill h4 {
  font-size: 35px;
  font-weight: 700;
  font-family: "Alegreya";
}

.top-skill p {
  margin: 20px 0px;
  color: var(--sub-text);
  font-size: 13px;
}

.top-skill-for-each-category {
  font-family: "Alegreya";
}

.find-talent-buttons {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.around-the-world {
  color: var(--secondary-color);
  font-family: "Alegreya";
  font-style: italic;
}

.find-talent-button-one {
  padding: 8px 20px;
  border: none;
  border-radius: 20px;
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.find-talent-button-two {
  padding: 8px 20px;

  border: none;
  border-radius: 20px;
  background-color: transparent;
  color: var(--sub-text);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.active {
  border: 1px solid var(--primary-color);
}
/* TaALENT AT YOUR FINGERTIP */
.talent-at-your-finngertip-container-two {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
  border-radius: 0 25px 25px 0;
  height: 600px;
  overflow: hidden;
}

.talent-at-your-finngertip-container {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  border-radius: 0 25px 25px 0;
  height: 600px;
  overflow: hidden;
}

.talent-at-your-finngertip-left {
  width: 50%;
  padding: 20px;
}

.talent-at-your-finngertip-details-talent {
  margin-top: 50px;
  margin-bottom: 50px;
  line-height: 40px;
}

.talent-at-your-finngertip-details {
  /* margin-top: 50px;
    margin-bottom:50px;
    line-height: 40px; */
}
.talent-at-your-finngertip-details-talent {
  color: #fbd4b7;
  font-size: 20px;
}

.talent-at-your-finngertip-details p {
  color: var(--sub-text);
}

.talent-at-your-finngertip-left-details-two div {
  border-bottom: 1px solid var(--primary-color);
}

.talent-at-your-finngertip-left-details div {
  border-bottom: 1px solid #8b6146;
}
.talent-at-your-finngertip-right {
  padding: 0;
  margin: 0;
  overflow: none;
  width: 40%;
  height: 100%;
}

.talent-at-your-finngertip-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.talent-at-your-finngertip-container-two {
  border-radius: 25px 0px 0px 25px;
}

.talent-at-your-finngertip-left-details {
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.talent-at-your-finngertip-left-details h6 {
  color: var(--main-color);
}

.talent-at-fingertip-header {
  font-size: 45px;
  color: var(--bg-light);
  line-height: 55px;
  font-weight: 700;
  width: 60%;
}

.forClient-button {
  padding: 15px 30px;
  border: none;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  cursor: pointer;
  color: var(--bg-light);
  margin-bottom: 30px;
}
.talent-at-fingertip-ptags-talent {
  color: #fbd4b7;
  margin: 15px 0px;
}

.talent-at-fingertip-ptags-two {
  color: var(--bg-light);

  margin: 10px 0px;
}
.talent-at-fingertip-ptags {
  color: var(--sub-text);
  margin: 15px 0px;
}

.talent-learn-more {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: white;
}

/* MINI CARD */
.minicard {
  height: 130px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  gap: 10px;
}

.minicard-start {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0px;
  font-size: 14px;
}

/* BENEFIT OF BSP */

/* TALETENT CATEGORY */
.talent-category-container {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.browse-talents {
  width: 90%;
  margin: auto;
  line-height: 50px;
}

.browse-talents header {
  font-size: 25px;
  font-weight: 100;
}
.browse-talents header h3 {
  font-size: 44px;
  font-weight: 700;
}
.browse-talents p {
  color: var(--bg-dark);
}
.TalentCategory-miniCard {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* WHY CLIENT AND TALENT */
.whyClientAndTalent {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-bottom: 2px solid #D9D9D947;
}

.whyClientAndTalent header {
  width: 100%;
  margin: auto;
  font-size: 40px;
  margin: 30px 0px;
}
.whyClientAndTalent header h4 {
  font-weight: 700;
}

.bigQuotation {
  width: 90%;
  margin: auto;
  font-size: 60px;
  color: var(--secondary-color);
}

.join-BSP {
  width: 80%;
  margin: 30px auto;
  text-align: center;
  font-size: 40px;
  font-family: sans-serif;
  font-weight: 500;
  color: black;
  font-weight: 600;
  line-height: 65px;
}
.join-BSP span {
  color: var(--sub-text);
}

/* PRODUCT MANEGER */
.product-maneger {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-manager-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.slide-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.control {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  font-size: 20px;
}
.active-control {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  border: 1px solid black;
}

/* .slide-arrows button:hover{
    border: 1px solid black;
} */

/* OUR BLOG */

.ourBlog {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* SECOND CARD */
.second-card {
  width: 90%;
  margin: 20px auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.second-page {
  height: 350px;
  width: 32%;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 1px rgba(193, 193, 193, 0.2);
  margin: 20px 0px;
}

.second-page-top {
  height: 50%;
  background-color: #dadbfc;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 20px 20px 0 0;
}

.second-page-top button {
  border: 1px solid;
  background-color: #dadbfc54;
  padding: 10px 25px;
  border-radius: 5px;
}

.second-page-top h3 {
  /* font-size: 50px;  */
  font-weight: 500;
  width: 90%;
  margin: 10px auto;
}

.second-page-buttom p {
  color: var(--bg-dark);
  margin-bottom: 30px;
}

.second-page-buttom {
  padding: 20px;
}

.date-time-section {
  display: flex;
  gap: 20px;
  color: var(--sub-text) !important;
  font-size: 14px;
}

.second-page-buttom h4 {
  font-size: 14px;
}
.second-page-buttom button {
  color: var(--bg-dark);
  border: none;
  background-color: transparent;
  box-shadow: 0px 0px 0px 01px rgba(123, 123, 123, 0.1);
  border-radius: 14px;
  margin-bottom: 30px;
  padding: 8px;
}

.second-page-buttom button:hover {
  cursor: pointer;
}

.second-page-buttom-button-div {
  display: flex;
  gap: 20px;
}

.second-page-top2 {
  background-color: #313a47;
  color: var(--bg-light);
}

.second-page-top3 {
  overflow: hidden;
}

.second-page-top3 img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.second-page-top2 button {
  border: 1px solid;
  background-color: #dadbfc54;
  padding: 15px 30px;
  border-radius: 5px;
}

.second-page-top2 h1 {
  font-size: 50px;
  font-family: sans-serif;
  font-weight: 500;
}

/* HIRE */

.hire-container {
  width: 90%;
  height: 250px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ebf5fe;
}

.hire-container .banner-img {
  height: 100%;
}

.hire-container .banner-img img {
  height: 100%;
}

.hire-container-detail {
  line-height: 50px;
  width: 60%;
  margin: auto;
}

.hire-container-detail button {
  padding: 15px 40px;
  background-color: var(--primary-color);
  color: var(--bg-light);
  border-radius: 10px;
  border: none;
}

/* FOOTER  */
.footer-section {
  width: 90%;
  margin: 80px auto;
  font-size: 14px;

  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-item {
  flex-basis: 100px;
  flex-grow: 1;
}

.logo-socials-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-socials-section .logo {
  width: 117px;
  height: 61px;
}
.logo-socials-section .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-copyright {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.footer-section ul li {
  list-style: none;
  text-decoration: none;
  color: var(--bg-dark);
  font-size: 14px;
  margin: 10px;
  cursor: pointer;
  width: fit-content;
  padding: 0px 5px;
}

.footer-link {
  text-decoration: none;
  color: var(--bg-dark);
}

.footer-section ul li:nth-child(1) {
  color: var(--primary-color);
}

.footer-policy {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
}

.footer-socialmedia {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 200px;
}

/* HERO2 */
.hire-vetted-talents-container-two{
  background-color: var(--primary-color);
  padding: 60px 0px;
}
.hire-vetted-talents-container-two .content {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.hire-vetted-talents-container-two .content{
  width: 90%;
  margin: auto;
}
.hire-vetted-talents {
  /* padding:5px 5px; */
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  color: var(--bg-light);
  margin-bottom: 20px;
  padding: 10px;
  /* border: 2px solid red;   */
}

.talent-at-your-finngertip-details h3 {
  font-weight: 500;
  width: 80%;
}

.talent-at-your-finngertip-details h3 span {
  font-weight: 800;
  font-family: "Alegreya";
}

.hire-vetted-talents p:nth-child(1) {
  background-color: #134271;
  padding: 5px;
  border-radius: 20px;
}

.get-started {
  width: 130px;
  height: 35px;
  /* padding: 15px 30px; */
  color: var(--bg-light);
  background: transparent;
  border: 1px solid var(--bg-light);
  border-radius: 10px;
}

/* POST A JOB */

.post-today-container {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  border-radius: 20px;
  height: 600px;
  overflow: hidden;
}

.post-today-left {
  width: 50%;
  padding-left: 30px;
}

.post-today-details {
  /* margin-top: 50px;
    margin-bottom:50px;
    line-height: 40px; */
}

.post-today-details-header {
  font-size: 40px;
  color: var(--bg-light);
  width: 80%;
}

.post-today-details p {
  width: 75%;
  font-size: 13px;
  margin: 30px 0px;
  color: var(--sub-text);
  line-height: 35px;
}

/* .talent-at-your-finngertip-details-talent{
    margin-top: 50px;
    margin-bottom:50px;
    line-height: 40px;
} */

.post-a-job {
  width: 110px;
  height: 35px;
  /* padding: 15px 30px; */
  color: var(--bg-light);
  background: transparent;
  border: 1px solid var(--bg-light);
  border-radius: 10px;
}

.post-today-right {
  padding: 0;
  margin: 0;
  overflow: none;
  width: 40%;
  height: 100%;
}

.post-today-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* START HIRING  */
.hire-container-secondPage {
  height: 300px;
  width: 90%;
  margin: auto !important;
  margin: 50px 0px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--startHire);
}

.hire-container-detail-secondPage-left {
  width: 50%;
  margin: auto;
  line-height: 50px;
  display: flex;
  justify-content: flex-start;
}

.hire-container-detail-secondPage-left header h3 {
  font-weight: 700;
  font-size: 46px;
  /* width: 100%; */
}

.hire-container-detail-secondPage-left p {
  font-size: 13px;
  font-weight: 500;
  color: var(--sub-text);
}

.hire-container-detail-secondPage-left button {
  width: 130px;
  height: 40px;
  background-color: var(--primary-color);
  color: var(--bg-light);
  border-radius: 10px;
  border: none;
}

.hire-container-detail-secondPage-right {
  /* height: 50%; */
  width: 30%;
  margin: auto;
  /* padding-right: 20px; */
}

.hire-container-detail-secondPage-right img {
  object-fit: contain;
}

/* BY SKILL */
.bySkill {
  width: 70%;
  margin: auto;
  display: block;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  padding-top: 15px;
  margin-bottom: 15px;
  font-weight: 700;
  cursor: pointer;
}

.by-skill-list {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bySkill button {
  border: none;
  background-color: var(--bg-light);
}

.selects {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.selects button {
  width: 180px;
  height: 35px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  border: none;
  margin: 10px 0px 0px 0px;
}

.arrowUp {
  font-size: 20px;
}

.arrowDown {
  /* padding: 10px;
    border: none;
    /* background-color: var(--bg-light); */
  font-size: 20px;
}

/* THIRD CARD */
.benefit-of-using-bsp-card {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-bottom: 30px 0px;
}
.bsp-card {
  height: 300px;
  width: 32%;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.bsp-card-top {
  height: 100px;
  width: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bsp-card-top img {
  /* width: 200px; */
  object-fit: contain;
}
.bsp-card-buttom {
  height: 200px;
  text-align: center;
}

.bsp-card-buttom h4 {
  font-size: 20px;
  margin: 20px 0px;
}

.bsp-card-buttom p {
  font-size: 13px;
  color: var(--sub-text);
  line-height: 25px;
}

/* FIND TALENT PAGE 2 */

/* SECOND PAGE */
.findTalentPage2-hero {
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-light);
  padding-top: 50px;
}

.findTalentPage2-left {
  width: 50%;
  padding: 60px;
}

.findTalentPage2-findTalent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 13px;
  /* color: var(--primary-color); */
}

.findTalentPage2-findTalent p:nth-child(1) {
  color: var(--primary-color);
}

.findTalentPage2-header {
  /* font-size: 60px; */
  color: black;
  font-size: 40px;
  width: 70%;
  margin: 20px 0px;
}

.findTalentPage2-header span {
  font-size: 50px;
  font-weight: 800;
  font-family: Alegreya;
}

.findTalentPage2-ptags {
  color: hsl(0, 0%, 59%);
  font-size: 13px;
  margin-bottom: 20px;
  width: 80%;
}

.findTalentPage2-button {
  height: 35px;
  width: 125px;
  color: var(--bg-light);
  background: var(--primary-color);
  border: 2px solid var(--bg-light);
  border-radius: 10px;
}

/* FOURTH CARD */

.fourth-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0px;
}

.search-card-section {
  width: 30%;
}
.search-talent-card-section {
  top: 20px;
  position: relative;
}
.search-talent-card-section .skills-section {
  font-size: 12px;
  font-weight: 500;
}
.search-talent-card{
  /* height: 400px; */
}

.favorite-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.fourth-card-infomartion {
  margin-bottom: 50px;
}

.fourth-card-image-section {
  position: relative;
  width: 110px;
  margin: auto;
}

.verified-symbol {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 30px;
  height: 30px;
}

.verified-symbol img {
  width: 100%;
}

.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
}

.user-avatar .user-img {
  width: 100%;

  background-size: cover;
  background-position: center top;
}

.fourth-page {
  height: 450px;
  border-radius: 20px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 30%;

}

.skill-section {
  font-size: 11px;
  margin: 18px 0px;
  flex-wrap: wrap;
  font-weight: 500;
}
.tool {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
}

.tool img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}
.rate-rating-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.rate {
  background-color: #f47c2026;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  font-weight: 600;
}
.star-section {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.fourth-page-top img {
  width: 200px;
  object-fit: contain;
}

.fouthCard-avatar-image {
  width: 100px;
}

.fouthCard-verified-image {
  position: absolute;
  top: 130px;
  left: 300px;
  /* width: 50px !important; */
  z-index: 1;
}

.designer-skill-rate-section {
  height: 50%;
}

.designer-name-and-location {
  margin: 10px 0px;
}

.icon-and-location {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 600;
  color: var(--sub-text);
  margin: 10px;
}

.icon-and-location svg,
.icon-and-location img {
  font-size: 12px;
}

.profile-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.profile-content div {
  padding: 7px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 10px;
}

.fourth-card-button {
  border: none;
  border-radius: 10px;
  background-color: var(--complimentary);
  color: var(--primary-color);
  font-weight: 700;
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
}

.view-more-adminstrators {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-more-adminstrators button {
  padding: 15px 100px;
  border: 1px solid;
  border-radius: 20px;
  background-color: var(--bg-light);
  /* color: var(--bg-light); */
  font-weight: 700;
}

.card-details-section {
  text-align: center;
  height: 70%;
}

/* FREQUENTLY ASKED QUESTIONS */
.frequently-asked-questions {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 100px;
  background-color: #f2f4f6;
}

.frequently-asked-questions-left {
  width: 50%;
}
.frequently-asked-questions-left header h1 {
  font-size: 50px;
}

.frequently-asked-questions-left p {
  font-size: 20px;
}

.frequently-asked-questions-right {
  width: 50%;
}

.question-and-button-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 50px;
  cursor: pointer;
}

.question-and-button-container button {
  border: none;
  background-color: var(--bg-light);
}

.answers {
  line-height: 30px;
  font-size: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}

/*  FREQUENTLY ASKED QUESTIONS IN TALENTPAGE */
.talent-page-frequently-asked-questions {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 100px;
  background-color: #f2f4f6;
}

.talent-page-frequently-asked-questions-left {
  width: 40%;
}
.talent-page-frequently-asked-questions-left header h4 {
  font-size: 40px;
  width: 70%;
}

.talent-page-frequently-asked-questions-left p {
  font-size: 15px;
  color: var(--sub-text);
  margin-top: 10px;
}

.talent-page-frequently-asked-questions-right {
  width: 50%;
}

.talent-page-question-and-button-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  cursor: pointer;
}

.talent-page-question-and-button-container h4 {
  color: black;
  width: 80%;
  margin-bottom: 0px;
}

.talent-page-question-and-button-container button {
  border: none;
  background-color: var(--bg-light);
  background-color: transparent;
}

.talent-page-answers {
  line-height: 20px;
  font-size: 13px;
  margin-bottom: 30px;
  color: var(--sub-text);
}

/* LANDING PAGE RESPOSIVENESS  */

.search-talent-card-section {
  top: 20px;
  position: relative;
}

@media (max-width: 390px) {
  .search-talent-card-section {
    top: 7px;
    position: relative;
  }
}
@media (max-width: 450px) {
  .hero {
    width: 100%;
    margin: auto;
    display: block;
    align-items: center;
    justify-content: space-evenly;
  }

  .hero figure {
    display: none;
    /* width: 100%; */
  }

  .hero-middle {
    width: 100%;
    text-align: center;
  }

  .hero-middle header h1 {
    font-size: 20px;
    color: var(--primary-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
  }

  .news {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 250px;
    margin: auto;
    margin-bottom: 20px;
    background-color: #ebf2f7;
    border-radius: 10px;
    font-size: 15px;
  }
  .news p:nth-of-type(1) {
    background-color: #134271;
    padding: 7px 15px;
    border-radius: 30px;
    color: white;
  }

  .popular-search p {
    display: none;
  }

  .popular-search-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .popular-search button {
    padding: 10px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    border: none;
    color: #134271;
    border-radius: 10px;
    margin: 10px 0px;
  }

  .brand-container {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px auto;
  }

  /* ELEVATING YOUR PROFESSIONAL PERFORMANCE */
  .ProfPerformance {
    width: 100%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: block;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .ProfPerformance-left {
    width: 100%;
    padding: 20px;
  }

  .profPerformance-right {
    width: 100%;
    display: block;
    justify-content: center;
    padding: 10px;
  }

  .profPerformance-right header h1 {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }

  .ProfPerformance-details {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 30px;
    padding: 5px;
  }

  /* TRUSTED BY */
  .find-talents h1 {
    font-size: 30px;
    font-weight: 500;
    padding: 6px;
  }

  /* TaALENT AT YOUR FINGERTIP */
  .talent-at-your-finngertip-container {
    height: 1000px;
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    background-color: var(--primary-color);
    border-radius: 0 0px 0px 0;
    /* overflow: hidden; */
    padding: 10px;
  }

  .talent-at-your-finngertip-left {
    width: 100%;
    padding: 20px;
  }

  .talent-at-your-finngertip-container-two {
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
    display: block;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-color);
    border-radius: 0 0px 0px 0;
    height: 600px;
    overflow: hidden;
  }

  .talent-at-your-finngertip-left {
    width: 100%;
    height: 60%;
    padding: 20px;
  }

  .talent-at-your-finngertip-left-details {
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }

  .talent-at-your-finngertip-right {
    padding: 0;
    margin: 30px 0px;
    overflow: none;
    width: 100%;
    height: 50%;
  }

  .talent-at-your-finngertip-right img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .talent-at-fingertip-header {
    font-size: 25px;
    color: var(--bg-light);
    line-height: 55px;
    font-weight: 700;
    width: 100%;
  }

  .talent-at-your-finngertip-container-two {
    width: 100%;
    height: 1500px;
    margin: auto;
    /* margin-bottom: 50px; */
    display: block;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-color);
    border-radius: 0 0px 0px 0;
    padding: 10px;
  }

  .talent-at-your-finngertip-container-two .talent-at-your-finngertip-right {
    width: 100%;
    height: 50%;
    padding: 0;
    overflow: none;
  }

  .talent-at-your-finngertip-container-two
    .talent-at-your-finngertip-right
    img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .talent-at-your-finngertip-container-two .talent-at-your-finngertip-left {
    height: 50%;
    width: 100%;
  }

  /* TALETENT CATEGORY */

  .browse-talents {
    width: 100%;
    margin: auto;
    line-height: 50px;
  }

  .browse-talents header h3 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  .browse-talents p {
    color: var(--bg-dark);
    text-align: center;
  }

  .TalentCategory-miniCard {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }

  .minicard {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 5px;
    /* gap: 10px; */
  }

  /* WHY CLIENT AND TALENT */
  .whyClientAndTalent {
    width: 100%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border-bottom: 2px solid var(--complimentary);
    padding: 10px;
  }

  .whyClientAndTalent header {
    width: 100%;
    margin: auto;
    font-size: 30px;
    margin: 20px 0px;
  }

  .whyClientAndTalent header h4 {
    font-weight: 700;
    text-align: center;
  }

  .bigQuotation {
    width: 90%;
    margin: auto;
    font-size: 40px;
    color: var(--secondary-color);
  }

  .join-BSP {
    width: 100%;
    margin: 20px auto;
    text-align: center;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 500;
    color: black;
    font-weight: 600;
  }

  /* SECOND CARD */
  .second-card {
    width: 100%;
    margin: 20px auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .second-page {
    height: 350px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 0px 1px rgba(193, 193, 193, 0.2);
    margin: 20px 0px;
  }

  /* HIRE */

  .hire-container {
    width: 100%;
    height: 500px;
    margin: auto;
    display: block;
    align-items: center;
    justify-content: space-between;
    background-color: #ebf5fe;
  }

  .hire-container-detail {
    line-height: 50px;
    width: 90%;
    height: 50%;
    margin: auto;
  }

  .hire-container .banner-img {
    height: 50%;
  }

  .hire-container .banner-img img {
    width: 100%;
  }

  /* HERO2 */

  .hire-vetted-talents-container-two {
    margin-bottom: 50px;
    margin-top: 100px;
    display: block;
    /* align-items: center;
    justify-content: center; */
    /* flex-direction: column-reverse; */
    background-color: var(--primary-color);
    padding: 10px;
  }

  .hire-vetted-talents {
    /* padding:5px 5px; */
    display: none;
  }

  .talent-at-your-finngertip-left {
    width: 100%;
    padding: 0px 0px;
  }

  .talent-at-fingertip-header {
    font-size: 30px;
    color: var(--bg-light);
    line-height: 55px;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }

  .talent-at-your-finngertip-details h3 {
    font-weight: 500;
    width: 100%;
  }
  .talent-at-fingertip-ptags {
    color: var(--sub-text);
    margin: 15px 0px;
    text-align: center;
  }

  .talent-at-your-finngertip-details {
    display: block;
    align-items: center;
    justify-content: center;
  }

  .get-started-btn {
    display: flex;
    justify-content: center;
  }

  /* TOP SKILL */
  .selects {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
  }

  .selects button {
    width: 180px;
    height: 35px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    border: none;
    margin: 10px 0px 0px 0px;
  }

  .post-today-container {
    height: 900px;
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
    display: block;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-radius: 20px;
    overflow: hidden;
  }

  .post-today-left {
    width: 100%;
    height: 50%;
    padding-left: 0px 0px;
  }

  .post-today-right {
    padding: 0;
    margin: 0;
    overflow: none;
    width: 100%;
    height: 50%;
  }

  /* START HIRING  */
  .hire-container-secondPage {
    height: 550px;
    width: 100%;
    margin: auto !important;
    margin: 50px 0px;
    border-radius: 20px;
    display: block;
    align-items: center;
    justify-content: space-between;
    background-color: var(--startHire);
  }

  .hire-container-detail-secondPage-left {
    height: 30%;
    width: 100%;
    line-height: 50px;
    display: block;
    justify-content: center;
    text-align: center;
  }

  .hire-container-detail-secondPage-right {
    height: 70%;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .hire-container-detail-secondPage-right img {
    width: 100%;
    object-fit: cover;
  }

  /* THIRD CARD */
  .benefit-of-using-bsp-card {
    width: 100%;
    margin: auto;
    display: block;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 10px;
    margin-bottom: 30px 0px;
  }

  .bsp-card {
    height: 300px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    padding: 15px;
  }

  /* FIND TALENT PAGE 2 */

  .findTalentPage2-hero {
    width: 100%;
    margin-bottom: 50px;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-light);
    /* padding-top: 50px; */
  }

  .findTalentPage2-left {
    width: 100%;
    padding: 0px 0px;
    text-align: center;
  }

  .findTalentPage2-findTalent {
    display: none;
  }

  .findTalentPage2-header {
    /* font-size: 60px; */
    color: black;
    font-size: 40px;
    width: 100%;
    margin: 20px 0px;
  }

  .findTalentPage2-ptags {
    color: hsl(0, 0%, 59%);
    font-size: 13px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  /* FOURTH CARD */

  .fourth-card {
    width: 100%;
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 20px 0px;
  }

  .fourth-page {
    height: 450px;
    border-radius: 20px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 100%;
    margin: 10px 0px;
  }

  /*  FREQUENTLY ASKED QUESTIONS IN TALENTPAGE */
  .talent-page-frequently-asked-questions {
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: #f2f4f6;
    padding: 10px;
  }

  .talent-page-frequently-asked-questions-left {
    width: 100%;
  }
  .talent-page-frequently-asked-questions-left header h4 {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }

  .talent-page-frequently-asked-questions-left p {
    font-size: 15px;
    color: var(--sub-text);
    margin: 10px 0px;
    text-align: center;
  }

  .talent-page-frequently-asked-questions-right {
    width: 100%;
    padding: 5px;
  }
}

@media (max-width: 800px) {
  .hero-middle header h1 {
    font-size: 30px;
    color: var(--primary-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    /* margin-top: 20px; */
  }

  .news {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 300px;
    margin: auto;
    margin-top: 0px;
    background-color: #ebf2f7;
    border-radius: 10px;
    font-size: 15px;
  }
  .news p:nth-of-type(1) {
    background-color: #134271;
    padding: 7px 22px;
    border-radius: 30px;
    color: white;
  }

  .popular-search {
    /* height: 70px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    /* letter-spacing: 10px; */
  }

  .popular-search button {
    padding: 10px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    border: none;
    color: #134271;
    border-radius: 10px;
    margin: 0px 0px;
  }

  .hire-vetted-talents {
    height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    color: var(--bg-light);
    margin-bottom: 20px;
    padding: 10px;
  }
}

@media (max-width: 840px) {
  .findTalentPage2-hero {
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
    display: block;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-light);
    /* padding-top: 50px; */
    padding: 50px 10px 0px 10px;
  }

  .hero {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 30px;
  }
}
