.bsp-news-and-update-container{
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;
}

.bsp-news-and-update-heading{
    margin-bottom: 30px;
}


.bsp-news-and-update-heading h4{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
}


.bsp-news-and-update-heading p{
    margin-bottom: 30px;
    font-size: 13px;
    color: var(--sub-text);
    width: 35%;
    line-height: 20px;
}

.bsp-news-and-update-image-and-writeup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 20px; */
    margin-bottom: 50px;
    text-decoration: none;
    color: black;
}

.bsp-news-and-update-image{
    width: 48%;
}

.bsp-news-and-update-image img{
    width: 100%;
}

.bsp-news-and-update-writeup{
    width: 48%;
}


.bsp-news-and-update-writeup h4{
    font-size: 30px;
    width: 60%;
    margin-bottom: 20px;
    line-height: 40px;
}

.bsp-news-and-update-writeup p{
    font-size: 13px;
    color: var(--sub-text);
    width: 70%;
    line-height: 20px;
}

.bsp-news-and-update-buttons{
    display: flex;
    align-items:center;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 50px;

}

.bsp-news-and-update-buttons .all{
    padding: 7px 20px;
    border-radius: 20px;
    background-color: transparent;
color: var(--primary-color);
    border: 1px solid var(--primary-color);

}

.bsp-news-and-update-buttons button{
   padding: 7px;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    border-radius: 20px;
    background-color: transparent;
    border: none;
}

/* START YOUR TALENT JOURNEY */
.start-your-talent-container{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.start-your-talent-left{
    height: 300px;
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--bg-light);
    border-radius: 20px;
}



.start-your-talent-left h4{
   font-size: 35px;
}


.start-your-talent-left h4:nth-child(2){
    font-weight: 100;
    font-family: 'Alegreya';
 }
 

.start-your-talent-left button{
    width: 140px;
    height: 40px;
  border: none;
  border-radius: 10px;
  color: var(--primary-color);
  background-color: var(--bg-light);
  margin-top: 30px;

}

.start-your-talent-right{
    width: 49%;
    height: 300px;
    background-color: 
    #f5f9ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    border-radius: 20px;


}

.start-your-talent-right h4{
    font-size: 35px;
    
 }

 .start-your-talent-right h4:nth-child(2){
    font-weight: 100;
    font-family: 'Alegreya';
 }
 

.start-your-talent-right button{
   width: 140px;
   height: 40px;
    border: none;
    border-radius: 10px;
    color: var(--bg-light);
    background-color: var(--primary-color);
    margin-top: 30px;
}


@media(max-width: 450px){
    .bsp-news-and-update-container{
        width: 100%;
        margin: 30px 0px;
    }

    .bsp-news-and-update-heading{
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
    }
    
.bsp-news-and-update-heading h4{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
}


.bsp-news-and-update-heading p{
    margin-bottom: 30px;
    font-size: 13px;
    color: var(--sub-text);
    width: 100%;
    line-height: 20px;
}

.bsp-news-and-update-image-and-writeup{
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    /* gap: 20px; */
    margin-bottom: 50px;
    text-decoration: none;
    color: black;
    text-align: center;
}


.bsp-news-and-update-buttons{
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 50px;

}

.bsp-news-and-update-image{
    width: 100%;
}

.bsp-news-and-update-image img{
    width: 100%;
}

.bsp-news-and-update-writeup{
    width: 100%;
}


.bsp-news-and-update-writeup h4{
    font-size: 30px;
    width: 100%;
    margin-bottom: 20px;
    line-height: 40px;
}

.bsp-news-and-update-writeup p{
    font-size: 13px;
    color: var(--sub-text);
    width: 100%;
    line-height: 20px;
}

/* START YOUR TALENT JOURNEY */
.start-your-talent-container{
    width: 100%;
    margin: auto;
    display: block;
    align-items: center;
    justify-content:center;
    text-align: center;
    gap: 20px;
    margin: 30px 0px;
}

.start-your-talent-left{
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--bg-light);
    border-radius: 20px;
}


.start-your-talent-right{
    width: 100%;
    height: 300px;
    background-color: 
    #f5f9ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    border-radius: 20px;
    margin: 10px 0px;


}

}