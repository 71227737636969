.dashboard-main-username {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  color: var(--sub-sub-text);

  & > span {
    font-weight: 700;
    color: var(--text);
  }
}

// DASHBOARD SUMMARY
.dashboard-summary-container {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin: 30px 0;
}

.dashboard-summary-item-container {
  flex: 1;
  aspect-ratio: 16/9;
  padding: 21.99px;
  border: 0.92px solid var(--secondary-neutral-100);
  border-radius: 7.33px;
  max-height: 117.26px;
  height: 100%;

  & > p {
    text-align: center;
    font-size: 16.49px;
    font-weight: 600;
    line-height: 29.32px;
    color: var(--secondary-neutral-500);
    margin-top: 10px;
  }
}

.dashboard-summary-item-detail {
  display: flex;
  justify-content: space-between;
}

.dashboard-summary-item-number-container {
  display: flex;
  gap: 5.95px;

  & > p {
    text-align: center;
    font-size: 29.32px;
    font-weight: 600;
    line-height: 50.39px;
    color: var(--primary-neutral-500);
  }
}

.dashboard-summary-item-view-more-container {
  > img {
    margin-bottom: -50px;
  }
}

// DASHBOARD CHART
.dashboard-chart-container {
  flex: 2;
  padding: 24px 10px;
  border: 1px solid var(--secondary-neutral-100);
  border-radius: 8px;

  .recharts-wrapper {
    height: 95% !important;
  }

  .recharts-surface {
    height: 95% !important;
    padding: 10px;
    overflow: visible !important;
    width: 99% !important;
  }
}
.dashboard-chart-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: var(--primary-neutral-500);
  }
}

.dashboard-chart-filter-container {
  display: flex;
  gap: 10px;
}

.dashboard-chart-filter {
  padding: 4px;
  cursor: pointer;

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--greyscale-grey-500);
  }
}

.dashboard-chart-filter-selected {
  background-color: var(--greyscale-grey-100);
  border: 1px solid var(--greyscale-grey-200);
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;

  & > p {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: var(--primary-neutral-500);
  }
}

/* Add more custom styles as needed */

// DASHBOARD PAGE PROFILE
.dashboard-chart-and-profile-container {
  display: flex;
  width: 100%;
  gap: 24px;
  max-height: 374px;
  height: 100%;
  margin-bottom: 30px;
}

// DASHBOARD APPLICATIONS
.dashboard-applications-container {
  padding: 24px 19px;
  border: 1px solid var(--secondary-neutral-100);
  border-radius: 8px;
  margin-bottom: 30px;
}

.dashboard-applications-header-container,
.dashboard-request-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: var(--primary-neutral-500);
  }

  & > a {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    line-height: 25.6px;
    color: var(--primary-color);
    text-decoration: none;
  }
}

.dashboard-applications-list-container {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 20px;
}

.dashboard-applications-list-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--secondary-neutral-100);
  border-radius: 8px;
  padding: 24px;
}

.dashboard-applications-company-details,
.dashboard-request-company-details {
  display: flex;
  gap: 10px;
}

.dashboard-applications-company-name {
  & > h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    color: var(--text);
  }

  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    color: var(--Neutrals-60);
  }
}

.dashboard-applications-date-details {
  font-size: 16px;
  line-height: 25.6px;

  & p:nth-child(1) {
    font-family: "Epilogue", sans-serif;
    font-weight: 500;
    color: var(--text);
  }

  & p:nth-child(2) {
    font-weight: 400;
    color: var(--sub-text);
  }
}

// DASHBOARD REQUEST AND PAYOUT
.dashboard-request-and-payouts-container {
  display: flex;
  gap: 24px;
  width: 100%;
}

// DASHBOARD REQUEST
.dashboard-request-container,
.dashboard-payout-container {
  flex: 1.5;
  padding: 24px;
  border: 1px solid var(--secondary-neutral-100);
  border-radius: 8px;
  // max-width: 551px;
  width: 50%;
}

.dashboard-request-list-container {
  display: flex;
  flex-direction: column;
  gap: 16.52px;
  margin-top: 20px;
}

.dashboard-request-list-item-container {
  display: flex;
  justify-content: space-between;
  border: 0.82px solid var(--secondary-neutral-100);
  border-radius: 6.57px;
  padding: 15px 19.7px 15px 9.85px;
}

.dashboard-request-company-name {
  & > h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 25.2px;
    color: var(--text);
  }

  & > p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18.82px;
    color: var(--Neutrals-60);
  }
}

// DASHBOARD PAYOUTS
.dashboard-payout-container {
  height: 100%;

  & > h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: var(--primary-neutral-500);
  }
}

.dashboard-payout-card {
  max-width: 502px;
  width: 100%;
  height: 272px;
  background-color: var(--primary-color);
  border-radius: 9px;
  padding: 24px;
  margin-top: 20px;
}

.dashboard-payout-card-logo {
  display: flex;
  gap: 250px;
  align-items: flex-start;
}

.dashboard-payout-amount {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;

  & > div > p {
    font-size: 12px;
    font-weight: 500;
    line-height: 17.25px;
    color: var(--text-light);
    margin-bottom: 10px;
  }

  & > div > h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 41.25px;
    color: var(--text-light);
    margin-bottom: 30px;
  }
}

.dashboard-payout-wallet {
  background-color: #f5f6f9;
  color: var(--text);
  padding: 10px;
  border-radius: 12px;
  max-width: 428px;
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

// DASHBOARD APPLICATIONS
.dashboard-recommended-talent-container {
  padding: 24px 19px;
  border: 1px solid var(--secondary-neutral-100);
  border-radius: 8px;
  margin-bottom: 30px;
}
