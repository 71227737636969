@import "../_mixins";

.sidebar-container {
  width: 18%;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;

  .sidebar-content {
    width: 80%;
    margin: 20px auto;
  }

  .logo-section {

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 20%;
    padding: 20px;
// background-color: #F3F6F8;
background-color: white;
z-index: 2;
    .logo{
      width: 120px;
      img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    }


    margin-bottom: 30px;
  }

  .sidebar-links-section {
    margin: 30px 0px;
    padding-bottom: 30px;
    color: #848fac;
    position: relative;
    top: 100px;

    a{
      text-decoration: none;
      color: #848fac;
    }

    .section-head {
      margin: 20px 0px;
    }

    .sidebar-item {
      @include flex-none-center;
      gap: 10px;
      margin: 20px 0px;
      font-weight: 300;
      transition: all 0.3s ease-in-out;
      border: 1px solid transparent;
      background-color: transparent;

      border-radius: 12px;
      padding: 10px;

      .icon {
        width: 20px;
        height: 20px;
        // font-size: 24px;
        // color: #848fac;
        img,
        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;

        }
      }

      .text {
        font-size: 14px;
      }
    }

    .active-sidebar-item {
      @extend .sidebar-item;
      border: 1px solid rgba(19, 66, 113, 0.2);
      background-color: rgba(19, 66, 114, 0.05);
      color: #134272;
      font-weight: 600;

      .icon {
        color: var(--primary-color);
        svg {
          fill: var(--primary-color);
          stroke: var(--primary-color);

        }
      }
    }
  }


}
  ::-webkit-scrollbar {
    width: 2px;
    background-color: #0d95891a;
  }
   ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
   ::-webkit-scrollbar-thumb {
    background: #c6c8d3;
    border-radius: 10px;
  }
   ::-webkit-scrollbar-thumb:hover {
    background: darken(#c6c8d3, 30%);
  }