// PROFILE DETAILS STYLING

.dashboard-profile-container {
  display: flex;
  width: 100%;
  padding: 20px;
  gap: 10px;
}

.dashboard-profile-details-container {
  // max-width: 337px;
  flex: 1;
  border: 0.66px solid var(--secondary-neutral-100);
  border-radius: 12px;
}

.dashboard-profile-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
}

.dashboard-profile-user-avatar {
  max-width: 273.29px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;

  & > div {
    position: absolute;
    max-width: 115px;
    width: 100%;
    height: 180px;
    margin-right: -90px;

    & > img {
      max-width: 115px;
      width: 100%;
      height: 100%;
      margin-top: 5px;
    }
  }

  & > img {
    max-width: 165px;
    width: 100%;
    height: 165px;
    border-radius: 50%;
    z-index: 1;
  }
}

.dashboard-profile-user-avatar-dashboard {
  max-width: 304px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;

  & > div {
    position: absolute;
    max-width: 55px;
    width: 100%;
    height: 100px;
    margin-right: -60px;

    & > img {
      max-width: 55px;
      width: 100%;
      height: 100%;
      margin-top: 7px;
    }
  }

  & > img {
    max-width: 100.62px;
    width: 100%;
    height: 100.62px;
    border-radius: 50%;
    z-index: 1;
  }
}

.dashboard-profile-user-detail {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > a {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--primary-color);
  }
}

.dashboard-profile-user-availability {
  display: inline-block;
  background-color: rgba(23, 165, 45, 0.15);
  padding: 5.64px 9.4px;
  border-radius: 7.52px;

  & > p {
    font-size: 11.28px;
    line-height: 15.03px;
    font-weight: 600;
    color: var(--availability-label-color);
  }
}
.dashboard-profile-user-availability-off {
  display: inline-block;
  background-color: rgba(255, 0, 0, 0.2);
  padding: 5.64px 9.4px;
  border-radius: 7.52px;

  & > p {
    font-size: 11.28px;
    line-height: 15.03px;
    font-weight: 600;
    color: red;
  }
}

.dashboard-profile-user-name {
  font-size: 25.21px;
  font-weight: 700;
  line-height: 33.17px;
  color: var(--primary-neutral-500);
}

.dashboard-profile-user-designation {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  color: var(--secondary-neutral-500);
}

.dashboard-profile-user-displayname {
  font-size: 16px;
  font-weight: 600;
  line-height: 33.17px;
  color: var(--primary-neutral-500);
}

.dashboard-profile-user-availability-switch {
  margin-top: 10px;
  width: 257px;
  height: 48px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: rgba(243, 243, 243, 0.7);
  padding: 5.97px 6px;
  border-radius: 12px;
}

.dashboard-profile-user-availability-switch-available {
  padding: 10px;
  border-radius: 8px;
  width: 118px;
  cursor: pointer;

  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: center;
  }
}

.dashboard-profile-user-availability-switch-invisible {
  padding: 10px;
  border-radius: 8px;
  width: 118px;
  cursor: pointer;

  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: center;
  }
}

.dashboard-profile-details-divider {
  width: 305.16px;
  border: 2px solid var(--secondary-neutral-100);
  margin: 0 auto;
  display: flex;
  align-items: center;
}

// GENERAL SECTIONS STYLING
.dashboard-profile-user-skill,
.dashboard-profile-user-Bio,
.dashboard-profile-user-location,
.dashboard-profile-user-rate,
.dashboard-profile-user-link {
  padding: 15px 15px;
}

.dashboard-profile-user-editable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;

  & > h5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 25.6px;
  }

  & > img {
    cursor: pointer;
  }
}

.dashboard-profile-user-skill-main {
  display: inline-block;
  background-color: var(--lgrey-03);
  margin: 0 10px 10px 0;
  padding: 7.24px 12.67px;
  border-radius: 10.86px;

  & > p {
    color: var(--text);
    font-size: 14.48px;
    font-weight: 400;
    line-height: 16.29px;
  }
}

.dashboard-profile-user-Bio > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text);
}

.dashboard-profile-user-location-main {
  display: flex;
  gap: 10px;
  align-items: center;

  & > p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    color: var(--sub-text);
  }
}

.dashboard-profile-user-rate-main > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  color: var(--sub-text);
}

.dashboard-profile-user-location-and-rating {
  display: flex;
  gap: 8px;

  & > div {
    display: flex;
  }

  & > div:first-of-type {
    gap: 8px;
  }
}

// PROFILE CERTIFICATIONS STYLING

.dashboard-profile-certifications-container {
  flex: 2;
}

.dashboard-profile-add-certifications-container,
.dashboard-profile-list-certifications-container {
  padding: 15px 15px 0;
}

.dashboard-profile-add-certifications {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 2px solid var(--secondary-neutral-100);

  & > h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 50px;
    color: var(--primary-neutral-500);
  }
}

.dashboard-profile-list-certifications {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0 20px;
  border-bottom: 2px solid var(--secondary-neutral-100);

  & > div:first-child {
    flex: 1;
    width: 20%;
    aspect-ratio: 16 / 9;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.dashboard-profile-list-certifications-details {
  flex: 2;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    color: var(--text);
  }

  & > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: var(--text);
  }

  & :nth-child(3) {
    margin-bottom: 15px;
  }
}

// EMPTY STATE

.dashboard-profile-list-certifications-empty {
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: white;

  // & > img {
  //   width: 150px;
  //   height: 150px;
  // }

  & > h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 18.82px;
    color: var(--text);
  }

  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.82px;
    color: var(--sub-text);
    margin-top: 10px;
  }

  & > button {
    max-width: 409px;
    width: 100%;
    background-color: #f5f6f9;
    padding: 10px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.82px;
    color: var(--text);
    margin-top: 40px;
    cursor: pointer;
  }
}

// DASHBOARD PAGE PROFILE
.dashboard-page-profile-detail {
  max-width: 352px;
  width: 100%;
  padding: 24px;
  border: 1px solid var(--secondary-neutral-100);
  border-radius: 8px;
}

.dashboard-page-profile-user-skill {
  margin-top: 10px;
}

.certification-modal-form-container {
  > p:first-child {
    font-weight: 700;
    font-size: 16px;
    color: #1c2634;
    text-align: left;
    margin-bottom: 15px;
  }

  > p:nth-child(2) {
    color: #848fac;
    font-size: 14px;
    font-weight: 500;
  }
}

.certificate-form-container {
  border: 1px solid #eff3fa;
  border-radius: 11px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.certificate-input-container-flex {
  display: flex;
  justify-content: space-between;
  gap: 7px;
}

.certificate-input-container {
  width: 100%;

  > p {
    font-size: 14px;
    font-weight: 500;
    color: #848fac;
  }

  > label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1c2634;
  }

  > .certificate-form-select {
    width: 100%;
    height: 52px;
    border: 1px solid #eff3fa;
    color: #848fac;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets/dashboard/icons/dropdown.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
  }

  > .certificate-form-input {
    width: 100%;
    height: 52px;
    border: 1px solid #eff3fa;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    color: #848fac;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .certificate-double-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #eff3fa;
    border-radius: 10px;
    padding: 2px;
    height: 52px;

    hr {
      height: 37.5px;
      border: 1px solid #eff3fa;
    }

    > .certificate-form-input {
      width: 80%;
      border: none;
      padding: 10px;
      outline: none;
      color: #848fac;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    > .certificate-form-select {
      width: 20%;
      border: none;
      color: #848fac;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 10px;
      border-radius: 10px;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url("../../assets//dashboard//icons//dropdown.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
    }
  }

  .certificate-form-textarea {
    color: #848fac;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 2px;
    border: 1px solid #eff3fa;
    border-radius: 10px;

    > textarea {
      resize: none;
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      border: none;
      outline: none;
    }

    > p {
      text-align: end;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}

.certificate-submit-button-container {
  display: flex;
  justify-content: flex-end;

  > button {
    background-color: #134271;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 17px;
    border-radius: 12px;
    border: none;
    width: 118px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.certificate-modal-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.certificate-modal-content {
  top: 90px;
  position: fixed;
  max-height: calc(100vh - 120px);
  height: auto;
  width: 664px;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--bg-light);
  overflow-y: auto;
}
.close-certificate-modal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.close-certificate-modal button {
  border: none;
  background-color: transparent;
  font-size: 20px;
}
