/* .modal-overlay{
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4)
} */

.modal-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.modal-content { 
  top: 90px;
  position: fixed;
  height: 450px;
  width: 500px;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--bg-light);
}
.close-signup-modal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.close-signup-modal button {
  border: none;
  background-color: transparent;
  font-size: 20px;
}

.modal-content figure {
  width: 200px;
  height: 200px;
  margin: auto;

  /* border: 1px solid ; */
}
.modal-content figure img {
  width: 100%;
  object-fit: cover;
}

.modal-content h2,
.modal-content p {
  text-align: center;
  margin: 15px 0px;
}

.modal-content p {
  color: var(--bg-dark);
}

.signup-and-login-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.signup-and-login-buttons button:nth-child(1) {
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: none;
  background-color: var(--primary-color);
  color: var(--bg-light);
  font-weight: 800;
}

.signup-and-login-buttons button:nth-child(2) {
  /* padding: 15px 30px; */
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
  background-color: var(--bg-light);
  color: var(--primary-color);
  font-weight: 800;
}
