@import "../mixins";

.job-details-container {
  .details-row {
    display: flex;
    gap: 10px;
  }
  .company-details {
    width: 25%;
    padding: 20px;
    box-shadow: 0px 0px 1px 0px rgb(198, 202, 206);
    border-radius: 10px;
    background-color: #ffffff;
    height: fit-content;

    .subtitle {
      font-size: 14px;
    }
    .logo {
      width: 100px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 1px 0px rgb(198, 202, 206);
      @include flex-center-center;

      img,
      svg {
        width: 70%;
        height: 70%;
        border-radius: 100%;
        object-fit: contain;
      }
    }

    button,
    .location,
    .name {
      margin: 10px 0px !important;
    }

    .name {
      text-transform: capitalize;
    }

    .line {
      width: 100%;
      margin: 10px 0px;
    }
    .location {
      color: grey;
      font-size: 12px;
    }
    .share-btn {
      width: 100%;
      background-color: #f5f6f9 !important;
      color: black !important;
      box-shadow: 0px 0px 1px 0px #f5f6f9 !important;
      padding: 15px !important;
    }
  }

  .job-details {
    padding: 20px;
    box-shadow: 0px 0px 1px 0px rgb(198, 202, 206);
    border-radius: 10px;
    background-color: #ffffff;
    width: 75%;

    .details-header {
      @include flex-between-center;
      .share-save-icons {
        @include flex-none-center;
        gap: 10px;

        .icon {
          @include flex-center-center;
          width: 24px;
          height: 24px;
          padding: 5px;
          box-shadow: 0px 0px 1px 0px rgb(198, 202, 206);
        }
      }

      > h2 {
        text-transform: capitalize;
      }
    }

    .summary {
      @include flex-between-center;

      margin: 20px 0px;
      .time-budget {
        @include flex-none-center;
        width: 80%;
        gap: 10px;
      }
      .time {
        @include flex-none-center;
      }

      .budget {
        color: var(--primary-color);
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: rgba(var(--primary-rgb), 0.1);
        font-size: 14px;
      }
    }

    .button-section {
      width: 20%;
    }

    .skills {
      @include flex-none-center;
      padding: 15px 0px;
      margin: 15px 0px;
      border-bottom: 1px solid rgb(229, 236, 238);
      gap: 10px;
      color: grey;
    }

    .skill {
      box-shadow: 0px 0px 1px 0px grey;
      padding: 10px;
      border-radius: 10px;
      font-size: 12px;
      color: var(--text-grey);
    }

    .text-section {
      padding: 0px 15px;

      .description,
      li {
        color: #7f879e;
        line-height: 27px;
        font-size: 14px;
        font-weight: 400;
      }

      ul {
        margin: 10px 0px;
      }

      .heading {
        margin: 15px 0px;
      }
    }
  }
}

.breadcrumb {
  @include flex-none-center;
  gap: 5px;
  margin-bottom: 20px;

  .icon {
    @include flex-none-center;
    font-size: 16px;
  }
  .text {
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 600;
  }
}
