* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Search-talent-hero-container {
  width: 100%;
  position: relative;
  margin-bottom: 150px;
}

.Search-talent-hero-top-container {
  width: 100%;
  background-color: var(--primary-color);
  /* padding: 150px; */
  color: var(--bg-light);
  padding: 50px 0px;
}

.Search-talent-hero-top-container-content {
  width: 90%;
  margin: auto;
  padding: 50px 0px;
}

.home-and-search-talent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 13px;
  margin-bottom: 20px;
}

.home-and-search-talent p:nth-child(3) {
  color: var(--sub-text);
}

.Search-talent-hero-top-container header h4 {
  font-size: 30px;
  font-weight: 700;
}

.Search-talent-hero-top-container header h4 span {
  font-weight: 100;
  /* font-size: 5px; */
  font-family: "Alegreya", serif;
  font-style: oblique;
}

.Search-talent-hero-top-container button {
  width: 100px;
  height: 40px;
  border: 1px solid var(--bg-light);
  background-color: transparent;
  border-radius: 10px;
  color: var(--bg-light);
  margin-top: 20px;
}

.search-and-location-container {
  width: 90%;
  padding: 50px;
  position: absolute;
  top: 280px;
  left: 100px;
  background-color: var(--bg-light);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  z-index: 1;
}

.search-and-location-container-dashboard {
  width: 95%;
  padding: 50px;
  background-color: var(--bg-light);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

.search-and-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: white;
}

.search-talent-container,
.location-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 15px;
}

.search-talent-container input,
.location-container input {
  border: none;
  outline: none;
}
.search-talent-container {
  border-radius: 10px 0px 0px 10px;
}

/* .location-container{
   border-left: 0.5px solid rgb(235, 238, 238);
} */
.vertical-line {
  height: 30px;
  border-right: 1px solid rgb(235, 238, 238);
  background-color: transparent;
  width: 1px;
}

.search-and-location-button button {
  padding: 17px 30px;
  border: none;
  border-radius: 0px 10px 10px 0px;
  background-color: var(--primary-color);
  color: var(--bg-light);
}
.rate-and-flter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.select-rate,
.select-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 20px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  cursor: pointer;
}

.popup-for-rate {
  position: absolute;
  top: 80%;
  left: 4%;
  z-index: 1;
  list-style: none;
  line-height: 40px;
  cursor: pointer;
  background-color: var(--bg-light);
  padding: 15px 120px 15px 15px;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
}

.checkbox-and-price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.checkbox-and-price input {
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border: none;
  background-color: var(--bg-light);
  cursor: pointer;
}

.checkbox-and-price label {
  font-weight: 800;
  /* font-size: 20px; */
}

.profile-content div {
  padding: 7px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 10px;
}

.search-talent-card-button {
  /* width: 100%; */
  height: 40px;
  margin: auto;
  border: none;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: var(--bg-light);
  font-weight: 700;
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 20px;
}

@media (max-width: 450px) {
  .home-and-search-talent {
    display: none;
  }

  .search-and-location-container {
    width: 100%;
    margin: auto;
    padding: 10px;
    position: absolute;
    top: 240px;
    left: 0px;

    /* right: 0px; */
    background-color: var(--bg-light);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    z-index: 1;
  }

  .Search-talent-hero-top-container {
    background-color: var(--primary-color);
    padding: 80px 0px;
    width: 100%;
    color: var(--bg-light);
    text-align: center;
  }

  .Search-talent-hero-top-container header h4 {
    font-size: 30px;
    font-weight: 700;
  }

  .search-and-location {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  .search-and-location-button button {
    padding: 17px 15px;
    border: none;
    border-radius: 0px 10px 10px 0px;
    background-color: var(--primary-color);
    color: var(--bg-light);
  }

  .popup-for-rate {
    position: absolute;
    top: 80%;
    left: 4%;
    z-index: 1;
    list-style: none;
    line-height: 40px;
    cursor: pointer;
    background-color: var(--bg-light);
    padding: 15px 120px 15px 15px;
    border-radius: 10px;
    font-weight: 700;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  }

  .Search-talent-hero-container {
    width: 100%;
    position: relative;
    margin-bottom: 80px;
  }
}

@media (max-width: 800px) {
  .search-and-location-container {
    width: 90%;
    padding: 30px;
    position: absolute;
    top: 290px;
    left: 40px;
    background-color: var(--bg-light);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    z-index: 1;
  }
}

@media (max-width: 884px) {
  .search-and-location-container {
    width: 90%;
    padding: 30px;
    position: absolute;
    top: 290px;
    left: 40px;
    background-color: var(--bg-light);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    z-index: 1;
  }
}

/* DASHBOARD FIND TALENT */
.dashboard-search-and-location-container-dashboard {
  width: 100%;
  padding: 24px 19px;
  border: 1px solid var(--secondary-neutral-100);
  border-radius: 8px;
  margin-bottom: 30px;
}

.dashboard-search-and-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #eff3fa;
  background-color: #fafcff;
}

.dashboard-search-and-location-button button {
  padding: 17px 30px;
  border: none;
  border-radius: 0px 4px 4px 0px;
  background-color: var(--primary-color);
  color: var(--bg-light);
}
