.settings-tabs-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;

  > span {
    padding: 15px;
    cursor: pointer;
  }
}

.security-container {
  margin-top: 20px;
  border: 1px solid #eff3fa;
  border-radius: 8px;
  padding: 32px 24px 68px 24px;

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 64px;

    > div > p:first-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 22.68px;
      color: #1b212d;
    }

    > div > p:last-child {
      font-size: 14px;
      line-height: 20px;
      color: #929eae;
    }

    > span {
      cursor: pointer;
      font-weight: 600;
      line-height: 25.5px;
      color: #134271;
    }
  }

  > div:nth-child(2) {
    margin-top: 32px;

    > p:first-child {
      margin-bottom: 32px;
    }

    > p:last-child {
      cursor: pointer;
      font-weight: 600;
      font-size: 18px;
      line-height: 22.68px;
      color: #ff2116;
    }
  }
}

.change-password-form-container {
  > p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22.68px;
    color: #1b212d;
  }
}

.change-password-submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  > button {
    background-color: #134271;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 17px;
    border-radius: 12px;
    border: none;
    width: 118px;
  }
}

.basic-info-container {
  margin-top: 20px;
  border: 1px solid #eff3fa;
  border-radius: 8px;
  padding: 32px 24px 68px 24px;

  > div > .basic-info-input-container {
    margin-top: 30px;
    display: grid;
    column-gap: 74px;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    > .basic-info-field-container {
      p:first-child {
        color: #1b212d;
        font-size: 18px;
        font-weight: 600;
      }

      p:last-child {
        color: #929eae;
        font-size: 14px;
      }
    }

    .basic-info-input {
      max-width: 365px;
      width: 100%;

      > input {
        width: 100%;
        height: 56px;
        padding: 10px;
        background-color: rgba(172, 181, 187, 0.1);
        border: 1px solid #eff3fa;
        outline: none;
        border-radius: 15px;
      }
    }
  }

  > div > button {
    background-color: rgba(239, 243, 250, 0.5);
    border-radius: 14px;
    border: none;
    padding: 12px 24px;
    cursor: pointer;
    color: rgba(108, 114, 120, 0.5);
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
  }
}
