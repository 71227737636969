.enterDetails_container {
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 46.5px;
    letter-spacing: -3%;
    color: #1c2634;
  }
  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    letter-spacing: -2%;
    color: #848fac;
  }
}

.enterDetails_buttons {
  display: flex;
  align-items: center;
  width: 50%;
  gap: 1em;
}
