/* NAVIGATIOM */
.Navigation {
  width: 100%;
  margin: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 2;
  background-color: var(--main-color);
  padding: 10px 0px;
}
.Navbar {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.left-nav {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  /* gap: 70px; */
  width: 117px;
  color: black;
  text-decoration: none;
}

/* .logo-section { */
/* width: 200px;
  height: 61px; */
/* display: flex;
    align-items: center; */
/* } */

.logo-section img,
.logo-section svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav-link {
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-size: 14px;
  display: block;
  cursor: pointer;
}

.right-nav-link-contianer {
  width: 40%;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  gap: 40px;
}

.right-nav-button-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.how-it-work-dropdown {
  position: absolute;
  top: 100%;
  left: 12%;
  z-index: 1;
  list-style: none;
  line-height: 40px;
  cursor: pointer;
  background-color: white;
  padding: 15px 120px 15px 15px;
  border-radius: 10px;
  font-weight: 700;
}

.right-nav {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.right-nav button {
  padding: 10px 20px;
  /* font-weight: 600; */
  font-size: 14px;
}

.right-nav button:nth-of-type(1) {
  background-color: transparent;
  border: none;
  border: 1px solid #134271;
  border-radius: 10px;
  color: #134271;
}

.right-nav button:nth-of-type(2) {
  background-color: transparent;
  border: none;
  border-radius: 10px;
  color: #134271;
}
.right-nav button:nth-of-type(2) {
  background-color: #134271;
  border: none;
  border: 2px solid #134271;
  border-radius: 10px;
  color: white;
}

.mobile-menu-switch {
  display: none;
}

@media (max-width: 450px) {
  .Navigation {
    width: 100%;
    margin: auto;
    position: -webkit-sticky;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 100;
    background-color: var(--main-color);
  }

  .desktop-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 80%;
  }
  /*
    .logo-section{
        width: 10%;
    }

    .logo-section svg{
        object-fit: cover;
    } */

  .desktop-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20x;
  }

  .Navbar {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* gap: 70%; */
    /* position: fixed; */
  }

  .left-nav {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* position: fixed; */
  }
  .logo-section {
    width: 100% !important;
  }
  .logo-section img,
  .logo-section svg {
    width: 100% !important;
    height: 100%;
  }

  .right-nav {
    width: 100%;
    flex-direction: column;
    /* justify-content: flex-end; */
    position: fixed;
    top: 65px;
    left: 0;
    padding: 10px;
    /* gap: 10px; */
    background-color: white;

    display: none;
  }
  .mobile-options {
    display: flex;
  }
  .mobile-menu-switch {
    display: block;
    font-size: 40px;
  }

  .right-nav-link-contianer {
    flex-direction: column;
  }

  .right-nav-button-container {
    flex-direction: column;
  }
}

@media (max-width: 778px) {
  .Navigation {
    width: 100%;
    margin: auto;
    position: -webkit-sticky;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 100;
    background-color: var(--main-color);
    margin-top: 0px;
  }
}

@media (max-width: 800px) {
  .Navigation {
    width: 100%;
    margin: auto;
    position: -webkit-sticky;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 100;
    background-color: var(--main-color);
  }

  .logo-section {
    width: 50px;
    height: 50px;
    /* display: flex;
        align-items: center; */
  }

  .logo-section img,
  .logo-section svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .right-nav button {
    padding: 5px 7px;
    /* font-weight: 600; */
    font-size: 14px;
  }

  .right-nav button:nth-of-type(1) {
    background-color: white;
    border: none;
    border: 1px solid #134271;
    border-radius: 10px;
    color: #134271;
  }

  .right-nav button:nth-of-type(2) {
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: #134271;
  }
  .right-nav button:nth-of-type(3) {
    background-color: #134271;
    border: none;
    border: 2px solid #134271;
    border-radius: 10px;
    color: white;
  }
}

@media (max-width: 840px) {
  .Navigation {
    width: 100%;
    margin: auto;
    position: -webkit-sticky;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 100;
    background-color: var(--main-color);
  }

  .logo-section {
    width: 50px;
    height: 50px;
    /* display: flex;
        align-items: center; */
  }

  .logo-section img,
  .logo-section svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .right-nav button {
    padding: 5px 7px;
    /* font-weight: 600; */
    font-size: 14px;
  }

  .right-nav button:nth-of-type(1) {
    background-color: white;
    border: none;
    border: 1px solid #134271;
    border-radius: 10px;
    color: #134271;
  }

  .right-nav button:nth-of-type(2) {
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: #134271;
  }
  .right-nav button:nth-of-type(3) {
    background-color: #134271;
    border: none;
    border: 2px solid #134271;
    border-radius: 10px;
    color: white;
  }
}
