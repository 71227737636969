* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --secondary-color: #fb8e0b;
  --bg-dark: #474958;
  --bg-grey: #e8e9ed;
  --bg-light: #f8f8fa;
  --text-light: #fff;
  --sub-text: #7f879e;
  --main-color: #f3f6f8;
  --primary-color: #134271;
  --primary-rgb: 19, 66, 113;
  --complimentary: #7d97b15c;
  --startHire: #e2f3ed;
  --availability-label-color: #17a52d;
  --primary-neutral-500: #1e1e1e;
  --secondary-neutral-500: #848fac;
  --secondary-neutral-100: #eff3fa;
  --lgrey-03: #f9f9f9;
  --text: #1c2634;
  --complimentary: #969696;
  --sub-sub-text: #6c7278;
  --Neutrals-60: #7c8493;
  --Primary-Warning-600: #db9b10;
  --greyscale-grey-100: #f4f4f5;
  --greyscale-grey-200: #e4e4e7;
  --greyscale-grey-500: #70707a;
}

/* @font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v8/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko70yyygA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
} */

@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Plus Jakarta Sans";
  background-color: var(--main-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subtitle {
  color: var(--sub-text);
}
.psp-secondary {
  color: var(--secondary-color);
}

header,
header h1,
header h2,
header h3,
header h4,
header h5 {
  font-family: "Alegreya";
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 0px 3px 0px rgb(216, 221, 223);
  padding: 5px;
  border-radius: 10px;
}

.star {
  color: #e1c03f;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.cursor {
  cursor: pointer;
}

.footerShowMore {
  display: flex;
  align-items: center;
  gap: 8px;
}

.message-button {
  height: 40px;
  margin: 0 auto;
  border: none;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: var(--bg-light);
  font-weight: 700;
  width: 80% !important;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
