@import "../mixins";

.navBar-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 20%;
  z-index: 2;
  // height: 60px;
  // background-color: #f3f6f8;
  background-color: white;
  border-bottom: 1px solid rgb(219, 221, 223);
  @include flex-between-center;
  padding: 20px;

  .nav-links-section,
  .profile-section {
    @include flex-none-center;
    gap: 20px;
  }
  .alert {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    box-shadow: 0px 0px 1px 0px grey;

    @include flex-center-center;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;

    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }

  .menu {
    color: grey;
  }
}

.menu-options-container {
  position: fixed;
  z-index: 2;
  right: 10px;
  top: 90px;
  background-color: var(--primary-color);
  width: 245px;
  padding: 10px;
  border-radius: 12px;
  color: white;

  .profile-section {
    @include flex-none-center;
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    gap: 20px;
    .names h4 {
      color: black;
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;

      img,
      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }
  }

  .item {
    font-size: 16px;
    padding: 10px;
    border-radius: 12px;
    margin: 10px 0px;
    @include flex-none-center;
    gap: 10px;
    cursor: pointer;

    .icon {
      font-size: 24px;
      @include flex-none-center;
    }

    transition: 0.3s all ease-in-out;
    &:hover {
      background-color: white;
      color: black;
    }
  }
}

.buttons {
  @include flex-end-center;
  gap: 20px;

  button {
    width: 120px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    background-color: var(--primary-color);
    padding: 10px;
    color: white;
    border: none;

    &:first-child {
      background-color: white;
      color: var(--primary-color);
    }
  }
}

.app-body {
  // height: 100px;
  position: absolute;
  left: 20%;
  right: 0;
  top: 90px;
  margin: 10px;
}
body {
  background-color: white;
}

.dashboardPostJobNav {
  background-color: transparent;
  border: none;
  text-decoration: none;
  border: 1px solid #1c2634;
  border-radius: 10px;
  color: #1c2634;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
