.goal_container {
 background-color: white;
 padding: 6em 4em;
 border-radius: 20px;
 margin-top: 3em;
 height: 70vh;
  h1 {
    font-size: 32px;
    letter-spacing: -3%;
    line-height: 38.5px;
    font-weight: 700;
    font-family: "Plus Jakarta Sans";
  }
  p {
    font-size: 18px;
    letter-spacing: -2%;
    line-height: 33px;
    font-weight: 500;
    font-family: "Plus Jakarta Sans";
    color: #848FAC;
  }
  .goal_cards {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
   width: 100%;
   margin-top: 30px;
   gap: 20px;
  }
}
