.text-center {
  text-align: center;
}

.error{
  font-size: 12px;
  color: red;
}

.text-danger{
  color: red !important;
}