.hero4{
/* height: 500px; */
width: 100%;
margin: auto;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
margin-bottom: 30px;

}

.explore-bsp-for-talents{
   color: var(--primary-color);
   font-weight: 800;
}

.hero4 header h4{
   font-size: 50px;
   font-family: 'Plus Jakarta Sans';
   width: 60%;
   margin: 10px auto;
}


.hero4 header h4 span{
  color: var(--secondary-color);
  font-family: 'Alegreya';
    font-style: italic;
}
.how-it-works-prompt{
   margin: 10px 0px;
   font-size: 12px;
   font-weight: 600;
}.how-it-works-prompt a{
  margin-left: 10px;
}
/* PSP FOR TALENT  */

.psp-For-Talent-Centent{
   width: 80%;
   margin: 80px auto;
}

.talent-content-rows{
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.telent-content-rows-left{
   width: 50%;
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   align-items: flex-start; 
}

.telent-content-rows-right{
   width: 45%;
   /* margin: auto; */
}

.middle-lines{
   text-align: center;
}

figure img{
   width: 85%;
}

.writeups{
   /* padding: 100px; */
}

.writeups h3{
   font-size: 30px;
   width: 60%;
   font-weight: 800;

}

.writeups p{
   font-size: 14px;
   margin: 25px 0px;
     width: 60%;
}

.writeups button{
   padding: 10px 20px;
   border: none;
   border-radius: 10px;
   background-color: var(--primary-color);
   color: var(--bg-light);
   font-size: 12px;
}

/* THE FUTURE OF HIRING IS HERE  */
.the-future-of-hiring{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-color: var(--primary-color);
   color: var(--bg-light);
   margin: 20px 0px ;
   padding: 30px;
}

.the-future-of-hiring-left{
   width: 30%;
}

.left-circle{
   height: 100px;
   width: 100px;
   background-color: #0a3158;
   border-radius: 100%;

}

.the-future-of-hiring-middle{
   width: 40%;
   text-align: center;
}

.the-future-of-hiring-middle h3{
  font-size: 50px;
  /* width: 70%;
  margin: auto; */
  margin-bottom: 20px;

}

.the-future-of-hiring-middle button{
  padding: 10px 20px;
  background-color: transparent;
  border: 1px solid var(--bg-light);
  border-radius: 10px;
  color: var(--bg-light);
  /* margin-bottom: 50px; */
 }

.the-future-of-hiring-right{
   width: 30%;
}

.right-circle{
   height: 30px;
   width: 30px;
   border-radius: 100%;
   background-color: #0a3158;
   margin-bottom: 50px;
}

.middle-right-circle-container{
   display: flex;
   align-items: center;
   justify-content: flex-end;
   margin-bottom: 70px;

}

.middle-right-circle{
   height: 100px;
   width: 100px;
   border-radius: 100%;
   background-color: #0a3158;

}

@media(max-width: 450px){
   /* PSP FOR TALENT  */
.hero4{
   margin-top: 80px;
}
.psp-For-Talent-Centent{
   width: 100%;
   margin-bottom: 30px;
}
.talent-content-rows{
   width: 100%;
   display: block;
   align-items: center;
   justify-content: center;
}

.telent-content-rows-left{
   width: 100%;
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   align-items: flex-start;
}


.telent-content-rows-left img{
   width: 100%;

}

.telent-content-rows-right{
   width: 100%;
   margin: auto;
   display: block;
   align-items: center;
   justify-content: center;
}

.telent-content-rows-right img{
   width: 100%;
}


.telent-content-rows-right h3, .telent-content-rows-right p{
   width: 80%;
   margin-left: 5%;
}

.telent-content-rows-right button, .telent-content-rows-right button{
   margin-left: 5%;
}

.telent-content-rows-left h3, .telent-content-rows-left p{
   width: 80%;
   margin-left: 5%;
}

.telent-content-rows-left button, .telent-content-rows-left button{
   margin-left: 5%;
}

.middle-lines{
   width: 100%;
   text-align: center;
}

/* THE FUTURE OF HIRING IS HERE  */
.the-future-of-hiring-left{
  display: none;
}

.the-future-of-hiring-right{
   display: none;
}

.the-future-of-hiring-middle{
   width: 100%;
   text-align: center;
}

.the-future-of-hiring-middle h3{
   font-size: 30px;
   margin-bottom: 20px;

 }
}