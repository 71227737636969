.registerUserDetails_container {
  background-color: #FCFCFC;
  padding: 6em 4em;
  border-radius: 20px;
  margin-top: 3em;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.steps {
 font-size: 18px;
 font-weight: 400;
 line-height: 34.63px;
 letter-spacing: -3%;
 color: #7F879E;
}
