@import"../mixins";

.prompt-container{
 background: rgba(0,0,0,0.2);
 height: 100vh;
 width: 100%;
 z-index: 12;
 position: fixed;
 top: 0;
 right: 0;
 left: 0;
 bottom: 0;
 display: flex;
 align-items: center;

 .prompt{
  //  position: fixed;
  // right: 0;
  // left:0;
  // top: 25vh;
  // z-index: 3;
  width: 400px;
  margin: auto;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
 }

  .text{
    margin-bottom: 30px;
    font-weight: 600;
    @include flex-between-center;
  }
}