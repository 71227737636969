.card_container {
  // min-width: 100%;
  // max-width: 24%;
  // width: 100%;
  border: 1px solid #dfe8f6;
  border-radius: 11.85px;
   padding: 20px 20px;

  .icon_holder {
  //  padding-top: 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;

   input{
    accent-color: #134271;
      &:hover {
        cursor: pointer;
      }
    }
   
  }

  h4{
    margin-top: 10px;
   font-size: 19px;
   font-weight: 800;
   line-height: 34.64px;
  }
}
