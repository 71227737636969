.postJob-nav-container {
  display: flex;
  justify-content: center;
  padding: 24px 32px;
  border-bottom: 1px solid #eff3fa;
}

.postJob-container {
  max-width: 664px;
  width: 100%;
  margin: 0 auto;
}

.postJob-step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 5px;

  > div > p:first-child {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #1c2634;
  }

  > div > p:last-child {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #848fac;
  }

  > p {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #848fac;
  }

  > p > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

.postJob-form-container {
  border: 1px solid #eff3fa;
  border-radius: 11px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.postJob-input-container {
  width: 100%;

  > label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1c2634;
  }

  > .postJob-form-select {
    width: 100%;
    height: 52px;
    border: 1px solid #eff3fa;
    color: #848fac;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets//dashboard//icons//dropdown.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
  }

  > .postJob-form-input {
    width: 100%;
    height: 52px;
    border: 1px solid #eff3fa;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    color: #848fac;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .postJob-double-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #eff3fa;
    border-radius: 10px;
    padding: 2px;
    height: 52px;

    hr {
      height: 37.5px;
      border: 1px solid #eff3fa;
    }

    > .postJob-form-input {
      width: 80%;
      border: none;
      padding: 10px;
      outline: none;
      color: #848fac;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    > .postJob-form-select {
      width: 20%;
      border: none;
      color: #848fac;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 10px;
      border-radius: 10px;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url("../../assets//dashboard//icons//dropdown.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
    }
  }

  .postJob-form-textarea {
    color: #848fac;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 2px;
    border: 1px solid #eff3fa;
    border-radius: 10px;

    > textarea {
      resize: none;
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      border: none;
      outline: none;
    }

    > p {
      text-align: end;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}

.postJob-submit-button-container {
  display: flex;
  justify-content: flex-end;

  > button {
    background-color: #134271;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 17px;
    border-radius: 12px;
    border: none;
    width: 118px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.postJob-currency {
  display: flex;
  justify-content: space-between;
}

.postJob-submit-button-container-2 {
  display: flex;
  justify-content: space-between;

  > button:first-child {
    color: #134271;
    background-color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 17px;
    border-radius: 12px;
    border: 1px solid #134271;
    width: 118px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  > button:last-child {
    background-color: #134271;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 17px;
    border-radius: 12px;
    border: none;
    width: 118px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.postJob-switch-input {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child > p:first-child {
    color: #1c2634;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }

  > div:first-child > p:last-child {
    color: #848fac;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}

.postJob-preview-container {
  border: 1px solid #eff3fa;
  border-radius: 11px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .postJob-preview-header {
    > p {
      font-size: 32px;
      font-weight: 700;
      line-height: 50px;
      color: #1e1e1e;
      text-transform: capitalize;
    }

    > div:nth-child(2) {
      display: flex;
      gap: 22px;
      align-items: center;

      > div {
        display: flex;
        gap: 6px;
        align-items: center;

        > p:nth-child(1),
        p:nth-child(2) {
          color: #848fac;
          line-height: 16px;
        }
      }

      > span {
        background-color: rgba(19, 66, 113, 0.1);
        padding: 11px 20px;
        border-radius: 16px;

        > p {
          color: #134271;
          line-height: 20px;
          font-weight: 700;
        }
      }
    }

    > div:nth-child(3) {
      display: flex;
      gap: 14px;
      margin: 10px 0;

      > span {
        border: 1px solid #eff3fa;
        padding: 12px;
        border-radius: 12px;
        cursor: pointer;

        > p {
          color: #848fac;
        }
      }
    }
  }

  hr {
    border: 1px solid #eff3fa;
  }

  .postJob-preview-description-container {
    padding: 10px 0;

    > .postJob-preview-description {
      > p:first-child {
        color: #1e1e1e;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        margin: 20px 0;
      }

      > p {
        color: #7f879e;
        line-height: 25.6px;
      }

      //   > ul {
      //     color: #7f879e;
      //     line-height: 25.6px;
      //     margin-left: 16px;
      //   }
    }
  }
}

.postedJobs-container {
  margin-top: 20px;

  > p {
    color: #475367;
    margin-bottom: 20px;
  }
}

.postJob-preview-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.css-rqglhn-MuiTable-root {
  border: 1px solid #e4e7ec;
  border-radius: 10px !important;
  border-collapse: separate !important;
  border-spacing: revert !important;
}

.css-15wwp11-MuiTableHead-root {
  width: 100%;
  background-color: #f9fafb;
}

.css-1ygcj2i-MuiTableCell-root {
  font-family: "Plus Jakarta Sans" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  border: none !important;
  padding: 12px 24px !important;
  color: #344054 !important;

  > div {
    display: flex;
    justify-content: space-between;
  }
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Plus Jakarta Sans" !important;
  border: none !important;

  > button {
    border: 1px solid #969696;
    border-radius: 13.22px;
    padding: 5px 14px;
    background-color: #ffffff;
    color: #969696;
    font-weight: 600;
    font-size: 14px;
    line-height: 22.04px;
  }
}

.css-1ex1afd-MuiTableCell-root:nth-child(1) {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 12px 24px !important;
  color: #344054 !important;
  text-transform: capitalize;
}

.css-1ex1afd-MuiTableCell-root:nth-child(2) {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 12px 24px !important;
  color: #7f879e !important;
  text-transform: capitalize;
}

.css-1ex1afd-MuiTableCell-root:nth-child(3) {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  padding: 12px 24px !important;
  color: #344054 !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  color: #7f879e !important;
  border-radius: 12px !important;
  border: 1px solid #eff3fa;
  box-shadow: 0px 4px 120px 0px rgba(151, 159, 183, 0.1),
    0px 4px 20px 0px rgba(150, 150, 150, 0.1) !important;
}
