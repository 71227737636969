/* NAVIGATIOM */
.reset-navigation{
    height: 200px;
    width: 100%;
    margin: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 100;
    background-color: var(--main-color);
    /* border: 2px solid; */
  }

  .reset-logo-container{
    height: 100%;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* border: 2px solid red; */
  }

  .reset-logo-link{
    /* border: 2px solid green ; */
    height: 100%;
    width: 150px;
  }

  .reset-logo-link img,
  .reset-logo-linksvg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  


.reset-password{
   height: 100vh;
   width: 100%;
    display: block;
    align-items: flex-start;
    justify-content: center;
  background-color: var(--main-color);
  

}